import {HPRODUCE_TASKS} from '@hconnect/common/hproduce/config'
import {TASKS_ASSIGNED_TO_ME_PARAM_KEY} from '@hconnect/common/hproduce/consts'
import {CardBox} from '@hconnect/uikit/src/lib2'
import {CalendarTodayOutlined} from '@mui/icons-material'
import {Box, Chip, SxProps, Theme, Typography} from '@mui/material'
import moment from 'moment'
import queryString from 'query-string'
import {useTranslation} from 'react-i18next'
import {generatePath} from 'react-router'

import {usePlantTimezone} from '../../shared/hooks/useTimezone'
import {useTranslationPrefix} from '../../shared/hooks/useTranslationPrefix'
import {CardLinkWrapper, DataContentWrapper, MyTasksSummary} from '../components'
import {useMyTasksSummary} from '../hooks'

type Props = {
  cardBoxSx?: SxProps<Theme>
  placeholderWrapperSx?: SxProps<Theme>
}

export const MyTasksContainer: React.FC<Props> = ({cardBoxSx, placeholderWrapperSx}) => {
  const {t} = useTranslation()
  const {hrocPrefix} = useTranslationPrefix()
  const timezone = usePlantTimezone()
  const startOfToday = moment.utc().tz(timezone).startOf('day')
  const endOfToday = moment(startOfToday).endOf('day')

  const timeRange = {
    startDate: startOfToday.toISOString(),
    endDate: endOfToday.toISOString()
  }

  const {data, isLoading, isError, isRefetching, refetch} = useMyTasksSummary(timeRange)

  const linkPath = getAssignedToMeMyTasksUrl()

  return (
    <CardLinkWrapper href={linkPath} data-test-id="my-tasks-summary-card-link-to-tasks">
      <CardBox sx={cardBoxSx} data-test-id="my-tasks-summary-card">
        <Box display="flex" flexDirection="row">
          <Box flex={1}>
            <Typography variant="h3" mb={2}>
              {t(`${hrocPrefix}.myTasksSummary.cardTitle`)}
            </Typography>
          </Box>
          <Chip
            icon={<CalendarTodayOutlined />}
            label={t(`${hrocPrefix}.label.today`)}
            size="small"
            sx={{mx: 1}}
          />
        </Box>
        <DataContentWrapper
          isLoading={isLoading}
          isError={isError}
          isRefetching={isRefetching}
          data={data}
          retryFunction={() => void refetch()}
          progressSx={{color: 'primary.main'}}
          placeholderWrapperSx={placeholderWrapperSx}
        >
          {data && <MyTasksSummary {...data} />}
        </DataContentWrapper>
      </CardBox>
    </CardLinkWrapper>
  )
}

const getAssignedToMeMyTasksUrl = () => {
  const path = generatePath(HPRODUCE_TASKS)
  const search = queryString.stringify({[TASKS_ASSIGNED_TO_ME_PARAM_KEY]: true})
  return `${path}?${search}`
}
