import {
  ChecklistTemplateType,
  ChecklistTemplateCategory,
  ChecklistTemplateKind,
  type ChecklistTemplateDto
} from '@hconnect/common/types'

export {ChecklistTemplateType, ChecklistTemplateCategory, ChecklistTemplateKind}
export type {ChecklistTemplateDto}

export type ChecklistTemplate = Pick<
  ChecklistTemplateDto,
  'id' | 'name' | 'description' | 'type' | 'category' | 'createdAt'
>
