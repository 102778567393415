import {type AppName} from '@hconnect/common/hproduce'
import {appUrl, hproduce} from '@hconnect/common/hproduce/config'
import {COCKPIT_HOST} from '@hconnect/common/hproduce/config/cockpit'
import {EnvUrls} from '@hconnect/common/hproduce/types'

const supportedAppHosts: Partial<Record<AppName, EnvUrls>> = {
  Cockpit: COCKPIT_HOST,
  HProduce: hproduce.url
}

export const getChecklistPageRefererUrl = (
  refererAppName: AppName | null,
  refererPath: string | null
) => {
  if (!refererAppName || !refererPath) return null

  const appHostConfig = supportedAppHosts[refererAppName]

  if (!appHostConfig) return null

  const hostUrl = appUrl(appHostConfig)
  const linkPath = `${hostUrl}${refererPath}`

  return linkPath
}
