import {alpha, Box, Skeleton, Stack, useTheme} from '@mui/material'
import type {ChartData, TooltipItem} from 'chart.js'
import {useMemo} from 'react'
import {Bar} from 'react-chartjs-2'
import {useTranslation} from 'react-i18next'

import {useTranslationPrefix} from '../../shared/hooks/useTranslationPrefix'
import {NO_VALUE_STRING} from '../consts'
import type {KpiTrendChartData} from '../types'
import {formatMonthYearWithPrefix, formatKpiValue} from '../utils'

type Props = {
  data: KpiTrendChartData
  unit?: string
}

const CHART_HEIGHT = '48px'

export const KpiTrendChart: React.FC<Props> = ({data, unit}) => {
  const {t, i18n} = useTranslation()
  const language = i18n.language
  const {hrocPrefix} = useTranslationPrefix()
  const {palette} = useTheme()
  const {data: datasetData, labels} = data

  const chartData: ChartData<'bar', number[], string> = useMemo(
    () => ({
      labels: labels.map((date) => formatMonthYearWithPrefix(date, hrocPrefix, t)),
      datasets: [
        {
          data: datasetData,
          borderColor: palette.primary.main,
          backgroundColor: function (context) {
            const chart = context.chart
            const {ctx, chartArea} = chart

            if (!chartArea) {
              // This case happens on initial chart load
              return
            }

            const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top)
            gradient.addColorStop(0, alpha(palette.primary.light, 0))
            gradient.addColorStop(1, alpha(palette.primary.light, 0.4))

            return gradient
          },
          hoverBackgroundColor: alpha(palette.primary.light, 0.4),
          borderWidth: 1,
          barPercentage: 0.98,
          categoryPercentage: 1
        }
      ]
    }),
    [palette, datasetData, labels, hrocPrefix, t]
  )

  const max = datasetData.reduce((max, value) => Math.max(max, value), 0)
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {...hiddenGridScaleOptions, border: {display: true}},
      y: {...hiddenGridScaleOptions, border: {display: false}, max}
    },
    interaction: {
      intersect: false,
      axis: 'x' as const,
      mode: 'nearest' as const
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        displayColors: false,
        caretSize: 0,
        callbacks: {
          label: function (context: TooltipItem<'bar'>) {
            const value = context.dataset.data[context.dataIndex]
            const unitText = unit ? unit : ''

            if (typeof value === 'number') {
              return `${formatKpiValue(value.toString(), language)} ${unitText}`
            }

            return `${NO_VALUE_STRING} ${unitText}`
          }
        }
      }
    }
  }

  return (
    <Box sx={{position: 'relative', width: '100%', height: CHART_HEIGHT}}>
      <Bar data={chartData} options={options} />
    </Box>
  )
}

const hiddenGridScaleOptions = {
  grid: {
    display: false
  },
  ticks: {
    display: false,
    backdropPadding: 0
  }
}

export const KpiTrendChartSkeleton: React.FC<{barsCount?: number}> = ({barsCount = 6}) => {
  return (
    <Stack direction="row" spacing={0.5} sx={{width: '100%', height: CHART_HEIGHT}}>
      {Array.from({length: barsCount}).map((_, key) => (
        <Skeleton key={key} variant="rectangular" sx={{flex: 1, height: '100%'}} />
      ))}
    </Stack>
  )
}
