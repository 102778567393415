import {type AxiosError} from 'axios'
import {useTranslation} from 'react-i18next'
import {UseMutationResult} from 'react-query'

import {useChecklistStepsConfig} from '../hooks/useChecklistStepsConfig'
import {SteppedFormProvider} from '../hooks/useStepperFormContext'
import {
  mapChecklistDtoToChecklistFormValues,
  mapChecklistFormValuesToChecklistDtoData
} from '../mappers'
import type {ChecklistDto, ChecklistFormValues, UpdateChecklistResponseData} from '../types'

import {SteppedForm} from './SteppedForm'

type Props = {
  checklist: ChecklistDto
  readOnly?: boolean
  onCancel?: () => void
  updateMutation?: UseMutationResult<UpdateChecklistResponseData, AxiosError, ChecklistDto['data']>
}

export const ChecklistForm = ({checklist, readOnly, onCancel, updateMutation}: Props) => {
  const {t} = useTranslation()
  const checklistStepsConfig = useChecklistStepsConfig(checklist.kind)
  const initialValues = mapChecklistDtoToChecklistFormValues(checklist, t)

  const handleSubmit = (values: ChecklistFormValues) => {
    updateMutation?.mutate(mapChecklistFormValuesToChecklistDtoData(values, checklist.kind))
  }

  return (
    <SteppedFormProvider
      stepsConfig={checklistStepsConfig}
      initialValues={initialValues}
      onCancel={onCancel}
      onSubmit={handleSubmit}
      isSubmitting={updateMutation?.isLoading}
      readOnly={readOnly}
    >
      <SteppedForm />
    </SteppedFormProvider>
  )
}
