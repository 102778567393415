import type {Control, FieldValues, Path} from 'react-hook-form'

export type FormFieldBase = {
  label: string
  name?: string
  required?: boolean
  readOnly?: boolean
  disabled?: boolean
  helperText?: string
  error?: boolean
  optional?: boolean
}

export type ControlledFormField<TField extends FieldValues = FieldValues> = Omit<
  FormFieldBase,
  'name'
> & {
  name: Path<TField>
  control: Control<TField>
}

export type AutocompleteOption<TValue extends string> = {id: TValue; label: string}
