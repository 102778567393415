import {getPermissionsByUserId, getUserProfile, Permission} from '@hconnect/apiclient'
import {User} from '@hconnect/apiclient/src/response/User'
import {LegalPage} from '@hconnect/common/components/legal/LegalPage'
import {TermsAndConditions} from '@hconnect/common/components/legal/TermsAndConditions'
import {ApiProvider} from '@hconnect/common/hooks/useApi'
import {whiteListByPermission, Env} from '@hconnect/common/hproduce'
import {NotificationProvider} from '@hconnect/uikit'
import {Loader} from '@hconnect/uikit/src/lib2'
import {AxiosError} from 'axios'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Route, Routes, Navigate, Link as RouterLink} from 'react-router-dom'

import {ProductRouteHelper} from './entrypointDashboard/components/ProductRoute'
import {getEntrypointDashboardRoutes} from './entrypointDashboard/entrypointDashboard.routes'
import {getHrocRoutes} from './hroc/hroc.routes'
import {useNavAccessibility} from './performanceDashboard/hooks/useNavAccessibility'
import {getPerformanceDashboardRoutes} from './performanceDashboard/performanceDashboard.routes'
import {checkFeatureFlag} from './shared/helpers/featureFlagHelpers'
import {ApiContextProvider} from './shared/hooks/useApi'
import {NavAccessibilityContextProvider} from './shared/hooks/useNavAccessContext'
import {Log} from './shared/lib/Log'
import {initLocalization} from './shared/localization'
import {SimpleErrorPage} from './shared/pages/SimpleErrorPage'
import {ApiConfig} from './shared/provider/createApi'
import {PRIVACY_POLICY, TERMS_AND_CONDITIONS} from './shared/routes'
import {AccessStatus} from './shared/types/navigationAccess.types'
import {Context} from './shared/types/temp.types'
import {getTasksRoutes} from './tasks/tasks.routes'

type InitReturnType = {context: Context; permissions: Permission[]} | undefined

const initialize = async (api: ApiConfig): Promise<InitReturnType> => {
  const {loginFlow} = api

  const [loginResponse] = await Promise.all([loginFlow.getLoginState(), initLocalization()])

  if (!loginResponse.loggedIn) {
    await loginFlow.startLoginProcess()
    return
  }

  const [user, permissions] = await Promise.all([
    getUserProfile(api.axiosInstance)(loginResponse.decodedToken.user_id),
    getPermissionsByUserId(api.axiosInstance)(loginResponse.decodedToken.user_id)
  ])
  const whiteList = whiteListByPermission(process.env.REACT_APP_STAGE as Env, permissions)

  if (whiteList.missedPlantIds) {
    whiteList.missedPlantIds.forEach((missedPlantId) =>
      Log.extras({userId: loginResponse.decodedToken.user_id, missedPlantId}).error(
        'missing config for plantId'
      )
    )
  }

  const context: Context = {
    whiteList,
    onLogout: () => void loginFlow.startLogoutProcess(),
    user: {
      email: loginResponse.decodedToken.email,
      name: user.name || user.username,
      userId: loginResponse.decodedToken.user_id,
      country: user?.country,
      dataPrivacyAcknowledgedVersion: user?.dataPrivacyAcknowledgedVersion,
      termsAcceptedVersion: user?.termsAcceptedVersion
    }
  }

  return {context, permissions}
}

export const App: React.FC<{context: Context; permissions: Permission[]}> = ({
  context,
  permissions
}) => {
  const {t} = useTranslation()

  let enablePerformanceDashboard = false

  const {data, isLoading, error} = useNavAccessibility()

  if (isLoading) {
    return null
  }

  if (!data && error) {
    enablePerformanceDashboard = false
  } else if (data) {
    const {status} = data
    if (!(status === AccessStatus.NoPermission || status === AccessStatus.NoAccessNoKpi)) {
      enablePerformanceDashboard = true
    }
  }

  const areTermsAndConditionsEnabled = checkFeatureFlag('enableTermsAndConditions')

  return (
    <NavAccessibilityContextProvider
      initialState={{
        navigationAccess: data ? data : {status: AccessStatus.NoPermission},
        isLoading,
        error
      }}
    >
      <NotificationProvider>
        <Routes>
          {ProductRouteHelper('Predict', context)}
          {ProductRouteHelper('Maintain', context)}
          {ProductRouteHelper('Planner', context)}
          {ProductRouteHelper('Settings', context)}
          {ProductRouteHelper('Cockpit', context)}

          {getEntrypointDashboardRoutes(context, enablePerformanceDashboard)}

          {getTasksRoutes({context, permissions})}

          {getHrocRoutes({context, permissions})}

          {areTermsAndConditionsEnabled && (
            <Route
              path={TERMS_AND_CONDITIONS}
              element={
                <LegalPage
                  routerLinkComponent={RouterLink}
                  user={context.user as never as User}
                  type="tc"
                />
              }
            />
          )}

          {areTermsAndConditionsEnabled && (
            <Route
              path={PRIVACY_POLICY}
              element={
                <LegalPage
                  routerLinkComponent={RouterLink}
                  user={context.user as never as User}
                  type="dpp"
                />
              }
            />
          )}

          {data &&
            enablePerformanceDashboard &&
            getPerformanceDashboardRoutes(context, permissions, t)}
          <Route path={'*'} element={<Navigate to="/" />} />
        </Routes>
      </NotificationProvider>
    </NavAccessibilityContextProvider>
  )
}

export const AppWrapper: React.FC<{api: ApiConfig}> = ({api}) => {
  const [data, setData] = useState<InitReturnType>()
  const [error, setError] = useState<AxiosError>()
  useEffect(() => {
    initialize(api)
      .then((d) => setData(d))
      .catch((e) => setError(e as AxiosError))
  }, [api])

  if (!data && error) {
    return (
      <SimpleErrorPage>
        <pre>{(error as Error)?.toString?.()}</pre>
        <pre>{JSON.stringify(error, undefined, 2)}</pre>
      </SimpleErrorPage>
    )
  }

  if (!data) {
    return <Loader data-test-id="initializing-hproduce" />
  }

  const areTermsAndConditionsEnabled = checkFeatureFlag('enableTermsAndConditions')

  return (
    <ApiContextProvider axiosInstance={api.axiosInstance}>
      <ApiProvider secureApi={null} publicApi={api.axiosInstance}>
        <App context={data.context} permissions={data.permissions} />
        {areTermsAndConditionsEnabled && (
          <TermsAndConditions user={data.context.user as never as User} />
        )}
      </ApiProvider>
    </ApiContextProvider>
  )
}
