import {Box, Container, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {NavLink} from 'react-router-dom'

import {Shell} from '../../shared/components/Shell'
import {Context} from '../../shared/types/temp.types'
import {AppMarketingBox} from '../components/AppMarketingBox'
import {APP_SELECT_SEGMENT} from '../consts'
import {useAppsByWhiteList} from '../hooks/useAppsByWhiteList'

type Props = {
  context: Context
}

export const UpSell: React.FC<Props> = ({context}) => {
  const {t} = useTranslation()
  const {unAllowed} = useAppsByWhiteList(context.whiteList)

  return (
    <Shell context={context}>
      <Container
        fixed
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingTop: 3,
          paddingBottom: 3,
          [theme.breakpoints.up('sm')]: {
            paddingTop: 8,
            paddingBottom: 8
          }
        })}
      >
        <Box
          sx={(theme) => ({
            width: '100%',
            textAlign: 'left',
            paddingY: 0,
            paddingX: 2,
            [theme.breakpoints.up('sm')]: {
              padding: 0,
              textAlign: 'center'
            }
          })}
        >
          <Box
            sx={(theme) => ({
              fontSize: 16,
              color: 'rgb(255 255 255 / 80%)',
              marginBottom: 2,
              '&::before': {
                content: "'\\3031'",
                display: 'inline',
                marginRight: 1
              },
              [theme.breakpoints.up('sm')]: {
                marginBottom: 4
              },
              '&:hover, &:focus': {
                color: theme.palette.common.white
              }
            })}
          >
            <Box
              component={NavLink}
              to={APP_SELECT_SEGMENT}
              sx={(theme) => ({
                fontSize: 16,
                color: 'rgb(255 255 255 / 80%)',
                '&:hover, &:focus': {
                  color: theme.palette.common.white
                }
              })}
            >
              {t('entryPoint.marketing.backToLauncher')}
            </Box>
          </Box>
          <Typography
            variant="h1"
            sx={(theme) => ({
              marginBottom: 3,
              fontSize: 24,
              color: theme.palette.common.white,
              [theme.breakpoints.up('sm')]: {
                marginBottom: 6,
                fontSize: 28
              }
            })}
          >
            {t('entryPoint.marketing.headline.question')}
            <br />
            {unAllowed.length > 0
              ? t('entryPoint.marketing.headline.statement')
              : t('entryPoint.marketing.headline.noApps')}
          </Typography>
        </Box>
        {unAllowed.length > 0 && (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              alignItems: 'flex-start',
              justifyContent: 'space-around',
              rowGap: 32
            }}
          >
            {unAllowed.map((app) => (
              <AppMarketingBox key={app.name} appConfig={app} />
            ))}
          </Box>
        )}
      </Container>
    </Shell>
  )
}
