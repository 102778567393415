import {Stack, Typography} from '@mui/material'
import React from 'react'

type Props = {
  title: string
  children: React.ReactNode
  subTitle?: string
}

export const ChecklistStepSection = ({title, subTitle, children}: Props) => {
  return (
    <Stack spacing={1.5}>
      <span>
        <Typography variant="h4">{title}</Typography>
        {subTitle && (
          <Typography variant="h5" color="text.secondary" fontWeight="normal">
            {subTitle}
          </Typography>
        )}
      </span>
      {children}
    </Stack>
  )
}
