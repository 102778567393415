import {AxiosError} from 'axios'
import {Moment} from 'moment'
import qs from 'qs'
import {useQuery, UseQueryOptions} from 'react-query'
import {useNavigate} from 'react-router-dom'

import {ApiPath} from '../../../shared/consts'
import {handleError} from '../../../shared/helpers/errorHandling'
import {useAxios} from '../../../shared/hooks/useApi'
import {type AggregatedWorkOrderSummaryDto} from '../../types'

const QueryKey = 'work-orders-summary'

type WorkOrdersSummaryParams = {
  plantId: string
  from: Moment
  to: Moment
  workOrderTypes?: string[]
}

export const useWorkOrdersSummary = (
  {plantId, ...searchParams}: WorkOrdersSummaryParams,
  options?: UseQueryOptions<AggregatedWorkOrderSummaryDto, AxiosError>
) => {
  const axiosInstance = useAxios()
  const navigate = useNavigate()
  const from = searchParams.from.toISOString()
  const to = searchParams.to.toISOString()

  return useQuery<AggregatedWorkOrderSummaryDto, AxiosError>(
    [QueryKey, plantId, from, to],
    async () => {
      const response = await axiosInstance.get<AggregatedWorkOrderSummaryDto>(
        `${ApiPath.WORK_ORDERS}/${plantId}/summary`,
        {
          params: {
            from,
            to,
            workOrderTypes: searchParams.workOrderTypes
          },
          paramsSerializer: (params) => qs.stringify(params, {arrayFormat: 'repeat'})
        }
      )

      return response.data
    },
    {
      onError: (err) => handleError(err, navigate),
      ...options
    }
  )
}
