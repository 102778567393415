import {AppStage, MockStore} from '@hconnect/common/mocking'

import {initializeMockData} from './initializeMockData'
import type {MockScenario} from './scenarios/scenario.type'

export {MockStore} from '@hconnect/common/mocking'

const appStage: AppStage | undefined = process.env.REACT_APP_STAGE as AppStage

if (!appStage) throw new Error('app stage is undefined')

// re-type and export the singleton
export const mockStore = MockStore.enableMocking<MockScenario>('HProduce', appStage)

const asyncImportMockData = () => {
  return Promise.all([
    import('./scenarios'),
    import('./data/persona'),
    import('./MockPermissions')
  ]).then(([scenarios, personas, permissions]) => {
    return {scenarios, personas, permissions}
  })
}

/**
 */
if (appStage === 'qa') {
  mockStore.onEnable(() => {
    void asyncImportMockData().then(({scenarios, personas, permissions}) => {
      initializeMockData(
        mockStore,
        personas.personas,
        scenarios.standardScenario,
        permissions.rolesToPermission
      )
      mockStore.users.login(personas.shiftLeaderWilliam)
    })
  })
}
