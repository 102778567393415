import {useSearchParams} from 'react-router-dom'

import {getUrlParam} from '../../shared/helpers'
import {SortOrder} from '../types'

export enum SearchParamsMode {
  TABLE_ONLY = 'tableOnly',
  TEMPLATE_DETAILS = 'templateDetails'
}

enum ParamName {
  MODE = 'mode',
  TEMPLATE_ID = 'templateId',
  PAGE = 'page',
  ROWS_PER_PAGE = 'rowsPerPage',
  SORT_ORDER = 'sortOrder'
}

type TableOnlyPageSearchParams = {
  mode: SearchParamsMode.TABLE_ONLY
}

type TemplateDetailsPageSearchParams = {
  mode: SearchParamsMode.TEMPLATE_DETAILS
  templateId: string
  page?: number
  rowsPerPage?: number
  sortOrder?: SortOrder
}

type ChecklistTemplatesPageSearchParams =
  | TableOnlyPageSearchParams
  | TemplateDetailsPageSearchParams

function urlParamsToSearchParams(params: URLSearchParams): ChecklistTemplatesPageSearchParams {
  const mode = getUrlParam<SearchParamsMode>(params, ParamName.MODE)

  switch (mode) {
    case undefined:
    case SearchParamsMode.TABLE_ONLY:
      return getTableOnlyModeParams()
    case SearchParamsMode.TEMPLATE_DETAILS:
      return getTemplateDetailsParams(params)
    default:
      throw new Error('Unknown mode given in URL')
  }
}

function getTableOnlyModeParams(): ChecklistTemplatesPageSearchParams {
  return {mode: SearchParamsMode.TABLE_ONLY}
}

function getTemplateDetailsParams(params: URLSearchParams): ChecklistTemplatesPageSearchParams {
  const templateId = getUrlParam(params, ParamName.TEMPLATE_ID)
  const page = getUrlParam(params, ParamName.PAGE, Number)
  const rowsPerPage = getUrlParam(params, ParamName.ROWS_PER_PAGE, Number)
  const sortOrder = getUrlParam<SortOrder>(params, ParamName.SORT_ORDER)

  if (!templateId) throw new Error('templateId must be given with templateDetails mode')

  return {mode: SearchParamsMode.TEMPLATE_DETAILS, templateId, page, rowsPerPage, sortOrder}
}

function checklistTemplatesPageParamsToUrlParams(searchParams: ChecklistTemplatesPageSearchParams) {
  switch (searchParams.mode) {
    case SearchParamsMode.TABLE_ONLY:
      return getTableOnlyModeUrlParams(searchParams)
    case SearchParamsMode.TEMPLATE_DETAILS:
      return getTemplateDetailsModeUrlParams(searchParams)
    default:
      throw new Error('Unknown mode')
  }
}

function getTableOnlyModeUrlParams(searchParams: TableOnlyPageSearchParams): URLSearchParams {
  const params = new URLSearchParams()

  params.append(ParamName.MODE, searchParams.mode)

  return params
}

function getTemplateDetailsModeUrlParams(
  searchParams: TemplateDetailsPageSearchParams
): URLSearchParams {
  const params = new URLSearchParams()

  params.append(ParamName.MODE, searchParams.mode)
  params.append(ParamName.TEMPLATE_ID, searchParams.templateId.toString())
  if (searchParams.page) {
    params.append(ParamName.PAGE, searchParams.page.toString())
  }
  if (searchParams.rowsPerPage) {
    params.append(ParamName.ROWS_PER_PAGE, searchParams.rowsPerPage.toString())
  }
  if (searchParams.sortOrder) {
    params.append(ParamName.SORT_ORDER, searchParams.sortOrder)
  }

  return params
}

type UseChecklistTemplatesPageSearchParametersReturn = [
  ChecklistTemplatesPageSearchParams,
  (newParams: ChecklistTemplatesPageSearchParams) => void
]

export const useChecklistTemplatesPageSearchParameters =
  (): UseChecklistTemplatesPageSearchParametersReturn => {
    const [params, setParams] = useSearchParams()
    const searchParams = urlParamsToSearchParams(params)

    const setSearchParams = (newParams: ChecklistTemplatesPageSearchParams) => {
      setParams(checklistTemplatesPageParamsToUrlParams(newParams))
    }

    return [searchParams, setSearchParams]
  }
