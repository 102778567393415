import {WorkOrderColorMap} from '../consts'
import type {
  AggregatedWorkOrderSummaryDto,
  WorkOrderSummaryChartDataset,
  WorkOrderTypeName
} from '../types'

function isWorkOrderTypeName(key: string): key is WorkOrderTypeName {
  return key in WorkOrderColorMap
}

export const mapToWorkOrderSummaryChartDatasets = (
  data: AggregatedWorkOrderSummaryDto
): WorkOrderSummaryChartDataset[] =>
  Object.keys(WorkOrderColorMap).map((typeName) => {
    if (!isWorkOrderTypeName(typeName)) {
      throw new Error(`Invalid work order type name: ${typeName}`)
    }

    const summary = data.workOrderTypeSummaries.find((summary) => summary.typeName === typeName)
    return {
      typeName,
      count: summary ? summary.count : 0,
      color: WorkOrderColorMap[typeName]
    }
  })
