import {TextField, TextFieldProps} from '@mui/material'

export const BaseTextField = ({InputProps, ...props}: TextFieldProps) => (
  <TextField
    variant={InputProps?.readOnly ? 'standard' : 'filled'}
    InputProps={{
      ...InputProps,
      disableUnderline: InputProps?.readOnly ? true : undefined
    }}
    {...props}
  />
)
