import type {HealthIndicatorsApi} from '@hconnect/common/components/kmsStatus'
import type {HttpError} from '@hconnect/common/types'
import type {AxiosError} from 'axios'
import {useQuery, type UseQueryOptions} from 'react-query'

import {ApiPath} from '../../../../shared/consts'
import {useAxios} from '../../../../shared/hooks/useApi'

export const QueryKey = 'health-indicators'
const REFETCH_INTERVAL_IN_MS = 1000 * 30

type HealthIndicatorsParams = {
  kilnId: string
  plantId: string
}

export const useGetHealthIndicators = (
  {kilnId, plantId}: HealthIndicatorsParams,
  options?: UseQueryOptions<HealthIndicatorsApi, AxiosError<HttpError>>
) => {
  const axiosInstance = useAxios()

  return useQuery<HealthIndicatorsApi, AxiosError<HttpError>>({
    queryKey: [QueryKey, plantId, kilnId],
    queryFn: async () => {
      const path = `${ApiPath.KMS}/${plantId}/signal/health-indicators`
      const response = await axiosInstance.get<HealthIndicatorsApi>(path, {
        params: {
          departmentId: kilnId
        }
      })

      return response.data
    },
    refetchInterval: REFETCH_INTERVAL_IN_MS,
    ...options
  })
}
