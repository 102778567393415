import {type Iso8601, type User, ChecklistStatus} from '@hconnect/common/types'

import type {ChecklistTemplateKind} from './checklist-templates.types'
import type {AutocompleteOption} from './form.types'

export {ChecklistStatus}

export enum Hazard {
  VehiclesEquipmentPedestrianSegregation = 'vehiclesEquipmentPedestrianSegregation',
  ElectricalEnergizedEquipmentLOTO = 'electricalEnergizedEquipmentLOTO',
  MechanicalStoredEnergyPressurizedSystems = 'mechanicalStoredEnergyPressurizedSystems',
  RotatingEquipment = 'rotatingEquipment',
  WorkingAtHeights = 'workingAtHeights',
  ConfinedSpace = 'confinedSpace',
  GravityOverheadHazardsFallingItemsRigging = 'gravityOverheadHazardsFallingItemsRigging',
  WorkOverWater = 'workOverWater',
  UseOfHeavyEquipment = 'useOfHeavyEquipment',
  LiftingOperations = 'liftingOperations',
  SharpToolsCuts = 'sharpToolsCuts',
  ThermalFireExplosion = 'thermalFireExplosion',
  ElectricalCordsToolConditions = 'electricalCordsToolConditions',
  SpillPotentialEnvironmentalImpactConcerns = 'spillPotentialEnvironmentalImpactConcerns',
  WeatherConditions = 'weatherConditions',
  WorkEnvironmentClimateHotCold = 'workEnvironmentClimateHotCold',
  ErgonomicsBodyPositionLineOfFire = 'ergonomicsBodyPositionLineOfFire',
  Radiation = 'radiation',
  WorkplaceNoiseDustFumesMistAsbestos = 'workplaceNoiseDustFumesMistAsbestos',
  ChemicalAndHazardousMaterialManualHandling = 'chemicalAndHazardousMaterialManualHandling',
  TrainingPersonalLimitations = 'trainingPersonalLimitations',
  FirstTimePerformingTaskNewTask = 'firstTimePerformingTaskNewTask',
  WorkEnvironmentPermitRequired = 'workEnvironmentPermitRequired',
  OverlappingWorkAreas = 'overlappingWorkAreas',
  WorkingAlone = 'workingAlone',
  Other = 'other'
}

export enum Risk {
  FallFromHeight = 'fallFromHeight',
  EngulfmentOrEntrapment = 'engulfmentOrEntrapment',
  SlipTripFall = 'slipTripFall',
  CutsLacerations = 'cutsLacerations',
  CrushInjuries = 'crushInjuries',
  PinchInjuries = 'pinchInjuries',
  StrainsSprains = 'strainsSprains',
  Electrocution = 'electrocution',
  CaughtInEquipment = 'caughtInEquipment',
  Drowning = 'drowning',
  Burns = 'burns',
  ForeignBodyInEye = 'foreignBodyInEye',
  ArcFlash = 'arcFlash',
  Frostbite = 'frostbite',
  HeatStress = 'heatStress',
  ImpactOrAbrasiveInjuries = 'impactOrAbrasiveInjuries',
  VehicleAccident = 'vehicleAccident',
  ChemicalExposure = 'chemicalExposure',
  LossOfConsciousness = 'lossOfConsciousness',
  HearingDamage = 'hearingDamage',
  HarmToCoworkers = 'harmToCoworkers',
  Other = 'other'
}

export enum YesNoFormValue {
  YES = 'yes',
  NO = 'no'
}

export type ChecklistBaseDto = {
  id: string
  createdAt: Iso8601
  status: ChecklistStatus
  preparedBy: User | null
}

export type YesNoItemWithComment = {
  value: boolean
  comment?: string // max256
}

export type YesNoItemWithCommentFormValues = {
  value: YesNoFormValue
  comment?: string
}

export type TakeFiveChecklistData = {
  safety: {
    peopleInformed: YesNoItemWithComment
    rightTools: YesNoItemWithComment
    fitForDuty: YesNoItemWithComment
    riskRecognized: YesNoItemWithComment
    reasonToWorkSafe: string // max128
    supervisor: User | null
  } | null
  hazardAssessment: {
    workToBeDone: string // max512
    hazards: Hazard[]
    hazardsComment: string // max512
    risks: Risk[]
    risksComment: string // max512
    eliminateControlHazard: string // max512
  } | null
}

export type TakeFiveChecklistDto = ChecklistBaseDto & {
  kind: ChecklistTemplateKind.TakeFive
  data?: TakeFiveChecklistData
}

export type TakeFiveSafetyStepFormValues = {
  peopleInformed: YesNoItemWithCommentFormValues
  rightTools: YesNoItemWithCommentFormValues
  fitForDuty: YesNoItemWithCommentFormValues
  riskRecognized: YesNoItemWithCommentFormValues
  reasonToWorkSafe: string
  supervisor: User | null
}

export type TakeFiveHazardAssessmentStepFormValues = {
  preparedBy: string | null
  workToBeDone: string
  hazards: AutocompleteOption<Hazard>[]
  hazardsComment: string
  risks: AutocompleteOption<Risk>[]
  risksComment: string
  eliminateControlHazard: string
}

export type TakeFiveChecklistFormValues = {
  kind: ChecklistTemplateKind.TakeFive
  safety: TakeFiveSafetyStepFormValues | null
  hazardAssessment: TakeFiveHazardAssessmentStepFormValues | null
}

export type ChecklistFormValues = TakeFiveChecklistFormValues

export type ChecklistDto = TakeFiveChecklistDto

export type UpdateChecklistResponseData = {
  id: string
  status: ChecklistStatus
}

export type ChecklistDoneForTemplateDto = {
  id: string
  completedAt: Iso8601
  preparedBy: string
}
