import {type AppName} from '@hconnect/common/hproduce'
import {PageContainer} from '@hconnect/uikit/src/lib2'
import {useTranslation} from 'react-i18next'
import {generatePath, useNavigate, useParams} from 'react-router'
import {useSearchParams} from 'react-router-dom'

import {useTranslationPrefix} from '../../shared/hooks/useTranslationPrefix'
import {ChecklistPageHeader} from '../components/ChecklistPageHeader'
import {ChecklistContainer} from '../containers/ChecklistContainer'
import {PLANT_CHECKLISTS_TEMPLATES} from '../routing'
import {getChecklistPageRefererUrl} from '../utils/navigation'

type PathParameter = {
  plantId?: string
  checklistId?: string
}

export const PlantChecklistPage = () => {
  const {plantId, checklistId} = useParams<PathParameter>()
  if (!plantId) throw new Error('plantId is missing')
  if (!checklistId) throw new Error('checklistId is missing')
  const {refererAppName, refererPath} = usePlantChecklistPageSearchParams()
  const defaultBackToPath = generatePath(PLANT_CHECKLISTS_TEMPLATES, {plantId})
  const targetBackToPath = refererPath ?? defaultBackToPath

  const {t} = useTranslation()
  const {hrocPrefix} = useTranslationPrefix()
  const navigate = useNavigate()

  // we need to handle back navigation from multiple sources
  const handleNavigateBack = () => {
    if (refererAppName === 'HProduce') {
      navigate(targetBackToPath)
      return
    }

    const refererBackUrl = getChecklistPageRefererUrl(refererAppName, refererPath)
    if (refererBackUrl) {
      // redirect to the referer app for referer from diffrent app
      window.location.replace(refererBackUrl)
      return
    }

    // fallback
    navigate(-1)
  }

  return (
    <PageContainer>
      <ChecklistPageHeader
        title={t(`${hrocPrefix}.takeFive.pageTitle`)}
        backButtonProps={{
          target: targetBackToPath,
          targetName: t(`${hrocPrefix}.action.backToChecklistDetails`),
          onNavigate: handleNavigateBack
        }}
      />
      <ChecklistContainer
        checklistId={checklistId}
        plantId={plantId}
        onCancel={handleNavigateBack}
      />
    </PageContainer>
  )
}

const usePlantChecklistPageSearchParams = (): {
  refererAppName: AppName | null
  refererPath: string | null
} => {
  const [params] = useSearchParams()
  const refererAppName = params.get('refererAppName') as AppName
  const refererPath = params.get('refererPath')

  return {refererAppName, refererPath}
}
