import {Controller, FieldValues} from 'react-hook-form'

import {useGetLabelWithSuffix} from '../../hooks/useInputLabelTranslation'
import type {ControlledFormField} from '../../types'
import {RadioButton} from '../inputs'

type RadioButtonFieldProps<
  TValue extends string,
  TField extends FieldValues
> = ControlledFormField<TField> & {
  options: {value: TValue; label: string}[]
  onChange?: (e: React.ChangeEvent<HTMLInputElement>, value: TValue) => void
  row?: boolean
}

export const RadioButtonField = <TValue extends string, TField extends FieldValues>({
  label,
  name,
  control,
  disabled,
  readOnly,
  optional,
  options,
  helperText,
  onChange,
  row
}: RadioButtonFieldProps<TValue, TField>) => {
  const groupLabel = useGetLabelWithSuffix({label, optional})

  return (
    <Controller
      name={name}
      disabled={disabled || readOnly}
      control={control}
      render={({field, fieldState}) => (
        <RadioButton
          {...field}
          label={groupLabel}
          onChange={(e, value) => {
            field.onChange(e, value)
            onChange?.(e, value as TValue)
          }}
          error={fieldState.invalid}
          helperText={fieldState.error?.message ?? helperText}
          options={options}
          row={row}
          readOnly={readOnly}
          disabled={disabled}
        />
      )}
    />
  )
}
