import {PLANT_CHECKLIST} from '@hconnect/common/hproduce/config'

import {
  CHECKLISTS,
  RELIABILITY_ENGINEER_DASHBOARD,
  PLANT_RELIABILITY_ENGINEER_DASHBOARD,
  PROCESS_ENGINEER_DASHBOARD,
  PLANT_PROCESS_ENGINEER_DASHBOARD
} from '../../shared/routes/routes'

export {
  CHECKLISTS,
  PLANT_CHECKLIST,
  RELIABILITY_ENGINEER_DASHBOARD,
  PLANT_RELIABILITY_ENGINEER_DASHBOARD,
  PROCESS_ENGINEER_DASHBOARD,
  PLANT_PROCESS_ENGINEER_DASHBOARD
}

export const PLANT_CHECKLISTS_TEMPLATES = `${CHECKLISTS}/plant/:plantId/templates`
