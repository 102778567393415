import {Stack, Typography} from '@mui/material'
import {useTranslation} from 'react-i18next'

import {useTranslationPrefix} from '../../shared/hooks/useTranslationPrefix'

type Props = {
  overdue: number
  totalOverdue: number
}

export const WorkOrdersOverdueTooltipTitle = ({overdue, totalOverdue}: Props) => {
  const {t} = useTranslation()
  const {hrocPrefix} = useTranslationPrefix()

  return (
    <Stack spacing={0.5}>
      <Typography fontWeight="medium">
        {`${totalOverdue} ${t(`${hrocPrefix}.workOrderSummary.label.overdue`)} (${t(
          `${hrocPrefix}.label.allTime`
        )})`}
      </Typography>
      <Typography>
        {`${overdue} ${t(`${hrocPrefix}.workOrderSummary.label.overdue`)} (${t(
          `${hrocPrefix}.label.last30days`
        )})`}
      </Typography>
    </Stack>
  )
}
