import {EquipmentRunningTimesDto} from '@hconnect/common/types'
import {AxiosError} from 'axios'
import {Moment} from 'moment'
import {useQuery, UseQueryOptions} from 'react-query'

import {ApiPath} from '../../../../shared/consts'
import {useAxios} from '../../../../shared/hooks/useApi'

const QueryKey = 'running-times'

type UseOperationHoursParams = {
  plantId: string
  from: Moment
  to: Moment
}

export const useOperationHours = (
  {plantId, ...searchParams}: UseOperationHoursParams,
  options?: UseQueryOptions<EquipmentRunningTimesDto[], AxiosError>
) => {
  const axiosInstance = useAxios()
  const fromDate = searchParams.from.toISOString()
  const toDate = searchParams.to.toISOString()

  return useQuery<EquipmentRunningTimesDto[], AxiosError>({
    queryKey: [QueryKey, plantId, fromDate, toDate],
    queryFn: async () => {
      const path = `${ApiPath.SHIFT_HANDOVER}/${plantId}/running-times`
      const response = await axiosInstance.get<EquipmentRunningTimesDto[]>(path, {
        params: {from: fromDate, to: toDate}
      })

      return response.data ?? []
    },
    ...options
  })
}
