import {Controller, FieldValues} from 'react-hook-form'

import {useGetLabelWithSuffix} from '../../hooks/useInputLabelTranslation'
import {ControlledFormField} from '../../types'
import {BaseTextField} from '../inputs'

type TextFieldProps<TField extends FieldValues> = ControlledFormField<TField> & {
  onChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
}

export const TextField = <TField extends FieldValues>({
  label,
  name,
  control,
  disabled,
  readOnly,
  helperText,
  optional,
  onChange
}: TextFieldProps<TField>) => {
  const inputLabel = useGetLabelWithSuffix({label, optional})

  return (
    <Controller
      name={name}
      disabled={disabled || readOnly}
      control={control}
      render={({field: {ref, ...field}, fieldState}) => (
        <BaseTextField
          {...field}
          inputRef={ref}
          disabled={disabled}
          InputProps={{
            readOnly
          }}
          label={inputLabel}
          onChange={(e) => {
            field.onChange(e)
            onChange?.(e)
          }}
          error={fieldState.invalid}
          helperText={fieldState.error?.message ?? helperText}
          inputProps={{
            'data-test-id': `text-field-${name}`
          }}
        />
      )}
    />
  )
}
