import {AxiosError} from 'axios'
import {Moment} from 'moment'
import {useQuery, UseQueryOptions} from 'react-query'
import {useNavigate} from 'react-router-dom'

import {ApiPath} from '../../../shared/consts'
import {handleError} from '../../../shared/helpers/errorHandling'
import {useAxios} from '../../../shared/hooks/useApi'
import {type ReliabilityDailyKpis} from '../../types'

const QueryKey = 'daily-performance-kpis'

type DailyPerformanceKpisParams = {
  plantId: string
  from: Moment
  to: Moment
}

export const useDailyPerformanceKpis = (
  {plantId, ...searchParams}: DailyPerformanceKpisParams,
  options?: UseQueryOptions<ReliabilityDailyKpis, AxiosError>
) => {
  const axiosInstance = useAxios()
  const navigate = useNavigate()
  const startDate = searchParams.from.toISOString()
  const endDate = searchParams.to.toISOString()

  return useQuery<ReliabilityDailyKpis, AxiosError>({
    queryKey: [QueryKey, plantId, startDate, endDate],
    queryFn: async () => {
      const path = `${ApiPath.SHIFT_HANDOVER}/${plantId}/daily-performance-kpis`
      const response = await axiosInstance.get<ReliabilityDailyKpis>(path, {
        params: {startDate, endDate}
      })

      return response.data
    },
    onError: (err) => handleError(err, navigate),
    ...options
  })
}
