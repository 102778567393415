import {ChevronRight} from '@mui/icons-material'
import {Box} from '@mui/material'

export const CARD_CHEVRON_CLASS = 'card-chevron'

export const CardLinkChevron = () => (
  <Box className={CARD_CHEVRON_CLASS}>
    <ChevronRight />
  </Box>
)
