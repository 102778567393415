import MockAdapter from 'axios-mock-adapter'

import {enableSampleConfigEndPoint} from './businessLogic/sample'

/**
 * Mocks for which a backend API exists at least on QA - these should be mocked for development
 * and cypress-testing purposes
 */
export const enableExistingApiMocks = (apiMockAdapter: MockAdapter) => {
  enableSampleConfigEndPoint(apiMockAdapter)
}

/**
 * Mocks for which a backend API does not yet exist and thus should always be enabled
 * This can be used when the Frontend starts with a task before a backend API is deployed/defined
 * etc during development and prototyping.
 */
export const enableNotYetExistingApiMocks = () => {
  // TODO add some thing here
}
