import {AppConfig} from '@hconnect/common/hproduce/types'
import {AppLogo} from '@hconnect/uikit/src/common/assets/HProduceApps/'
import {Box, Button, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

export interface AppMarketingBoxProps {
  appConfig: AppConfig
}

export const AppMarketingBox: React.FC<AppMarketingBoxProps> = ({appConfig}) => {
  const {name: appName, contact} = appConfig
  const {t} = useTranslation()
  const appTPath = `entryPoint.marketing.${appName.toLowerCase()}`
  return (
    <Box
      sx={(theme) => ({
        width: '100%',
        maxWidth: 372,
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        borderRadius: 1,
        padding: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start'
      })}
    >
      <Box
        sx={{
          alignSelf: 'center',
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
          marginBottom: 4
        }}
      >
        <AppLogo name={appName} size={80} />
        <Typography
          variant="h2"
          sx={{
            marginTop: 1,
            fontSize: 16,
            fontWeight: 400,
            textTransform: 'none',
            lineHeight: '22px'
          }}
        >
          {appName}
        </Typography>
      </Box>
      <Typography
        variant="body1"
        sx={(theme) => ({
          color: theme.palette.text['primary'],
          fontWeight: 700,
          fontSize: 20,
          lineHeight: '24px',
          marginBottom: 2
        })}
      >
        {t(`${appTPath}.headline`)}
      </Typography>
      <Typography
        variant="body1"
        sx={(theme) => ({
          color: theme.palette.text['primarySoft']
        })}
      >
        {t(`${appTPath}.info`)}
      </Typography>
      {contact && (
        <Button
          variant="outlined"
          href={`mailto:${contact.mail}`}
          sx={(theme) => ({
            marginTop: 3,
            color: theme.palette.common.black,
            alignSelf: 'center',
            textTransform: 'none',
            borderColor: theme.palette.grey['200'],
            boxShadow: theme.shadows[3]
          })}
        >
          {contact.profileImage && (
            <Box
              component="img"
              src={contact.profileImage}
              alt={contact.name}
              sx={{
                width: 24,
                height: 24,
                borderRadius: '100%',
                marginRight: 1
              }}
            />
          )}
          {t('entryPoint.marketing.contact')} {contact.name}
        </Button>
      )}
    </Box>
  )
}
