import {customThemeConstants} from '@hconnect/uikit/src/lib2'

import type {WorkOrderTypeName} from '../types'

export const WorkOrderColorMap: Record<WorkOrderTypeName, string> = {
  PM01: customThemeConstants().palette.semanticInfoFaded30,
  PM02: customThemeConstants().palette.accent2faded30,
  PM03: customThemeConstants().palette.accent3faded30,
  PM04: customThemeConstants().palette.accent4faded30,
  PM05: customThemeConstants().palette.accent5faded30,
  PM06: customThemeConstants().palette.accent1faded30,
  PM07: customThemeConstants().palette.semanticInfoFaded20,
  PM08: customThemeConstants().palette.accent1faded20,
  PM09: customThemeConstants().palette.accent2faded20
} as const
