import {AxiosError} from 'axios'
import {Moment} from 'moment'
import {useQuery, UseQueryOptions} from 'react-query'
import {useNavigate} from 'react-router-dom'

import {ApiPath} from '../../../shared/consts'
import {handleError} from '../../../shared/helpers/errorHandling'
import {useAxios} from '../../../shared/hooks/useApi'
import {type MonthlyAggregatedPlantProductionKpisDto} from '../../types'

const QueryKey = 'monthly-aggregated-kpis'

type MonthlyAggregatedKpisParams = {
  plantId: string
  from: Moment
  to: Moment
}

export const useMonthlyAggregatedKpis = <TSelect = MonthlyAggregatedPlantProductionKpisDto[]>(
  {plantId, ...searchParams}: MonthlyAggregatedKpisParams,
  options?: UseQueryOptions<MonthlyAggregatedPlantProductionKpisDto[], AxiosError, TSelect>
) => {
  const axiosInstance = useAxios()
  const navigate = useNavigate()
  const {from, to} = searchParams
  const fromYear = from.year()
  const toYear = to.year()
  // months are 0-indexed in moment.js so we need to add 1 for the API
  const fromMonth = from.month() + 1
  const toMonth = to.month() + 1

  // cannot be passed in params because axios is serializing this not in the way API expects
  const query = `?from.year=${fromYear}&from.month=${fromMonth}&to.year=${toYear}&to.month=${toMonth}`

  return useQuery<MonthlyAggregatedPlantProductionKpisDto[], AxiosError, TSelect>({
    queryKey: [QueryKey, plantId, from.toISOString(), to.toISOString()],
    queryFn: async () => {
      const path = `${ApiPath.KPI}/actual-production-values/${plantId}/kpis/monthly-aggregated`
      const response = await axiosInstance.get<MonthlyAggregatedPlantProductionKpisDto[]>(
        path + query
      )

      return response.data
    },
    onError: (err) => handleError(err, navigate),
    ...options
  })
}
