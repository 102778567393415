import {ChecklistTemplateKind} from '../../types'
import {type StepsConfig} from '../useStepperFormContext'

import {useTakeFiveStepsConfig} from './useTakeFiveStepsConfig'

export const useChecklistStepsConfig = (
  checklistTemplateKind: ChecklistTemplateKind
): StepsConfig => {
  switch (checklistTemplateKind) {
    case ChecklistTemplateKind.TakeFive:
      return useTakeFiveStepsConfig()
    default:
      throw new Error(`Unknown checklistTemplateKind: ${checklistTemplateKind}`)
  }
}
