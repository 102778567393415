import type MockAdapter from 'axios-mock-adapter/types'
import moment from 'moment-timezone'

import {mockStore} from '../mockStoreSingleton'

export const enableSampleConfigEndPoint = (apiMockAdapter: MockAdapter) => {
  apiMockAdapter.onGet('/sample/config').reply(() => {
    // if an override for the current server time is set, use it - else use current time
    // (used in cypress tests)
    const scenario = mockStore.scenario()
    const currentTimeUtc = scenario.currentTimeUtc ?? moment.utc().toISOString()
    return [200, {sample: true}, {Date: currentTimeUtc}]
  })
}
