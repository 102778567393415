import {BackToLink as UiKitBackToLink} from '@hconnect/uikit/src/lib2'
import {useNavigate} from 'react-router'

type Props = {
  target: string
  targetName: string
  onNavigate?: (target: string) => void
}

export const BackToLink = (props: Props) => {
  const navigate = useNavigate()

  return <UiKitBackToLink {...props} navigate={props.onNavigate ?? navigate} />
}
