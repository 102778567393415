import {KpiScore} from '@hconnect/uikit/src/lib2'
import {InfoOutlined, KeyboardArrowDown, KeyboardArrowRight} from '@mui/icons-material'
import {Box, Collapse, Divider, Stack, Tooltip, Typography} from '@mui/material'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {useTranslationPrefix} from '../../shared/hooks/useTranslationPrefix'
import {mapToWorkOrderSummaryChartDatasets} from '../mappers/work-orders.mappers'
import type {WorkOrderSummaryChartDataset, AggregatedWorkOrderSummaryDto} from '../types'

import {WorkOrdersOverdueTooltipTitle} from './WorkOrdersOverdueTooltipTitle'

type Props = {
  data?: AggregatedWorkOrderSummaryDto
}

export const WorkOrdersSummary = ({data}: Props) => {
  const [isCollapsed, setIsCollapsed] = useState(false)

  if (!data) return null

  const datasets = mapToWorkOrderSummaryChartDatasets(data)

  return (
    <Stack spacing={2} useFlexGap>
      <DataSummary
        total={data.totalWorkOrders}
        overdue={data.overdueWorkOrders}
        totalOverdue={data.totalOverdueWorkOrders}
        closed={data.closedWorkOrders}
      />
      <SummaryDetails
        data={datasets}
        isCollapsed={isCollapsed}
        onToggleCollapsed={() => setIsCollapsed((prev) => !prev)}
      />
    </Stack>
  )
}

type DataSummaryProps = {
  total: number
  overdue: number
  totalOverdue: number
  closed: number
}

const DataSummary = ({total, overdue, totalOverdue, closed}: DataSummaryProps) => {
  const {t} = useTranslation()
  const {hrocPrefix} = useTranslationPrefix()
  const isOverdueWarningShown = totalOverdue > 0 && overdue !== totalOverdue
  const overdueVariant = totalOverdue > 0 ? 'negative' : 'neutral'

  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{
        justifyContent: 'center',
        alignItems: 'flex-end',
        alignSelf: 'stretch'
      }}
    >
      <StackItem>
        <KpiScore
          label={t(`${hrocPrefix}.workOrderSummary.label.total`)}
          value={total}
          data-test-id="total-work-orders"
        />
      </StackItem>
      <StackItem>
        <Tooltip
          title={
            isOverdueWarningShown ? (
              <WorkOrdersOverdueTooltipTitle overdue={overdue} totalOverdue={totalOverdue} />
            ) : (
              ''
            )
          }
          arrow
          placement="top"
        >
          <span>
            <KpiScore
              label={t(`${hrocPrefix}.workOrderSummary.label.overdue`)}
              labelIcon={isOverdueWarningShown ? <InfoOutlined /> : undefined}
              value={totalOverdue}
              variant={overdueVariant}
              data-test-id="overdue-work-orders"
            />
          </span>
        </Tooltip>
      </StackItem>
      <StackItem>
        <KpiScore
          label={t(`${hrocPrefix}.workOrderSummary.label.closed`)}
          value={closed}
          data-test-id="closed-work-orders"
        />
      </StackItem>
    </Stack>
  )
}

const StackItem: React.FC<{children: React.ReactNode}> = ({children}) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      flex: '1 0 0'
    }}
  >
    {children}
  </Box>
)

type SummaryDetailsProps = {
  isCollapsed: boolean
  onToggleCollapsed: () => void
  data: WorkOrderSummaryChartDataset[]
}

const SummaryDetails = ({data, isCollapsed, onToggleCollapsed}: SummaryDetailsProps) => {
  const {t} = useTranslation()
  const {hrocPrefix} = useTranslationPrefix()

  return (
    <>
      <Stack
        spacing={2}
        direction="row"
        alignItems="center"
        onClick={(event) => {
          event.preventDefault()
          event.stopPropagation()
          onToggleCollapsed()
        }}
      >
        {isCollapsed ? <KeyboardArrowRight /> : <KeyboardArrowDown />}
        <Typography fontWeight="bold">
          {t(`${hrocPrefix}.workOrderSummary.label.details`)}
        </Typography>
      </Stack>
      <Collapse in={!isCollapsed}>
        <Stack spacing={0.5} data-test-id="work-order-summary-chart-legend">
          {data.map(({typeName, count}, index) => {
            const showDivider = index !== data.length - 1

            return (
              <>
                <SummaryDetailsItem
                  key={typeName}
                  label={t(`${hrocPrefix}.workOrderSummary.sapWorkOrderTypes.${typeName}`)}
                  value={count}
                  data-test-id={`legend-item-for-${typeName}`}
                />
                {showDivider && <Divider />}
              </>
            )
          })}
        </Stack>
      </Collapse>
    </>
  )
}

const SummaryDetailsItem = ({label, value}: {label: string; value: number}) => (
  <Box
    sx={{
      display: 'flex',
      paddingY: 1.5,
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: 2,
      alignSelf: 'stretch'
    }}
  >
    <Typography>{label}</Typography>
    <Typography fontWeight="bold">{value}</Typography>
  </Box>
)
