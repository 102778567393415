import {FlexPage} from '@hconnect/common/components/FlexPage'
import {Box, Stack, useMediaQuery, useTheme} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router'

import {useTranslationPrefix} from '../../shared/hooks/useTranslationPrefix'
import {cardBoxSizes, placeholderWrapperSizes} from '../consts'
import {
  KilnMonitoringContainer,
  OperationHoursContainer,
  PlantStatusDailyKpisContainer,
  PlantStatusKpisContainer,
  RcfaContainer,
  WorkOrdersSummaryContainer
} from '../containers'
import {MyTasksContainer} from '../containers/MyTasksContainer'

type PathParameter = {
  plantId?: string
}

export const ReliabilityEngineerDashboard = () => {
  const {plantId} = useParams<PathParameter>()
  if (!plantId) throw new Error('plantId is missing')

  const {t} = useTranslation()
  const {hrocPrefix} = useTranslationPrefix()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <FlexPage title={t(`${hrocPrefix}.reliabilityEngineerDashboard.pageTitle`)}>
      {isMobile ? (
        <MobileLayout
          kilnMonitoring={
            <KilnMonitoringContainer plantId={plantId} placeholderWrapperSx={{width: '100%'}} />
          }
          workOrdersSummary={<WorkOrdersSummaryContainer plantId={plantId} variant="list" />}
          plantStatusKpis={<PlantStatusDailyKpisContainer plantId={plantId} />}
          operationHours={<OperationHoursContainer plantId={plantId} />}
          rcfa={
            <RcfaContainer
              plantId={plantId}
              cardBoxSx={{width: '100%'}}
              placeholderWrapperSx={{...placeholderWrapperSizes['S'], width: '100%'}}
            />
          }
          myTasks={
            <MyTasksContainer
              cardBoxSx={{width: '100%'}}
              placeholderWrapperSx={{...placeholderWrapperSizes['S'], width: '100%'}}
            />
          }
        />
      ) : (
        <DesktopLayout
          kilnMonitoring={
            <KilnMonitoringContainer plantId={plantId} cardBoxSx={cardBoxSizes['L']} />
          }
          workOrdersSummary={
            <WorkOrdersSummaryContainer
              plantId={plantId}
              cardBoxSx={cardBoxSizes['L']}
              variant="chart"
            />
          }
          plantStatusKpis={<PlantStatusKpisContainer plantId={plantId} />}
          operationHours={
            <OperationHoursContainer plantId={plantId} cardBoxSx={cardBoxSizes['M']} />
          }
          rcfa={
            <RcfaContainer
              plantId={plantId}
              cardBoxSx={cardBoxSizes['S']}
              placeholderWrapperSx={placeholderWrapperSizes['S']}
            />
          }
          myTasks={
            <MyTasksContainer
              cardBoxSx={cardBoxSizes['S']}
              placeholderWrapperSx={placeholderWrapperSizes['S']}
            />
          }
        />
      )}
    </FlexPage>
  )
}

type LayoutProps = {
  kilnMonitoring: React.ReactNode
  workOrdersSummary: React.ReactNode
  plantStatusKpis: React.ReactNode
  operationHours: React.ReactNode
  rcfa: React.ReactNode
  myTasks?: React.ReactNode
}

const DesktopLayout: React.FC<LayoutProps> = ({
  kilnMonitoring,
  workOrdersSummary,
  plantStatusKpis,
  operationHours,
  rcfa,
  myTasks
}) => {
  return (
    <Stack direction="row" spacing={2} flexGrow={1} useFlexGap flexWrap="wrap">
      <Stack spacing={2} flex={1} useFlexGap>
        {kilnMonitoring}
        {workOrdersSummary}
      </Stack>
      <Stack spacing={2} flex={1} useFlexGap>
        {plantStatusKpis}
        <Stack direction="row" spacing={2} flex={1} useFlexGap flexWrap="wrap">
          {operationHours}
          <Stack spacing={2}>
            {rcfa}
            {myTasks}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}

const MobileLayout: React.FC<LayoutProps> = ({
  kilnMonitoring,
  workOrdersSummary,
  plantStatusKpis,
  operationHours,
  rcfa,
  myTasks
}) => {
  return (
    <Stack spacing={1} useFlexGap flex={1}>
      {kilnMonitoring}
      {plantStatusKpis}
      {workOrdersSummary}
      {operationHours}
      <Stack spacing={1} direction="row" flex={1} useFlexGap flexWrap="wrap">
        <Box sx={{display: 'flex', flex: '1 0 0'}}>{rcfa}</Box>
        <Box sx={{display: 'flex', flex: '1 0 0'}}>{myTasks}</Box>
      </Stack>
    </Stack>
  )
}
