import {PermissionType} from '@hconnect/apiclient'
import React from 'react'
import {Navigate, useParams} from 'react-router'

import {useHasPermission} from '../../shared/hooks/usePermission'

type PathParameter = {
  plantId?: string
}

type Props = {
  children: React.ReactNode
  permission: PermissionType
  plantSelectPath: string
}

export const PlantPermissionGuardedRoute = ({children, permission, plantSelectPath}: Props) => {
  const {plantId} = useParams<PathParameter>()
  if (!plantId) throw new Error('plantId is missing')

  const hasPlantPermission = useHasPermission(plantId)

  if (!hasPlantPermission(permission)) {
    return <Navigate to={plantSelectPath} />
  }

  return <>{children}</>
}
