export enum SortOrder {
  ASCENDING = 'ascending',
  DESCENDING = 'descending'
}

export type PagedResponse<T> = {
  items: T[]
  totalCount: number
}

export type TimeRange = {
  startDate: string
  endDate: string
}
