import {ChecklistTemplatesList} from '../components/ChecklistTemplatesList'
import {DataContentWrapper} from '../components/DataContentWrapper'
import {useChecklistTemplates} from '../hooks/api'
import {type ChecklistTemplate} from '../types'

type Props = {
  plantId: string
  selectedTemplateId?: string
  onTemplateClick: (template: ChecklistTemplate) => void
}

export const ChecklistTemplatesListContainer: React.FC<Props> = ({
  plantId,
  selectedTemplateId,
  onTemplateClick
}) => {
  const {data, isLoading, isRefetching, isError, refetch} = useChecklistTemplates({plantId})

  return (
    <DataContentWrapper
      isLoading={isLoading}
      isError={isError}
      data={data}
      retryFunction={() => void refetch()}
      isRefetching={isRefetching}
    >
      <ChecklistTemplatesList
        data={data}
        selectedTemplateId={selectedTemplateId}
        onTemplateClick={onTemplateClick}
      />
    </DataContentWrapper>
  )
}
