import {yupResolver} from '@hookform/resolvers/yup'
import {Stack} from '@mui/material'
import React from 'react'
import {
  useForm,
  FormProvider,
  type FieldValues,
  type SubmitHandler,
  DefaultValues
} from 'react-hook-form'
import {object as yupObject, type AnyObjectSchema} from 'yup'

import {useSteppedFormContext} from '../hooks/useStepperFormContext'

type StepComponentProps<TStepFieldValues extends FieldValues> = {
  validationSchema?: AnyObjectSchema
  defaultValues?: DefaultValues<TStepFieldValues>
  values?: TStepFieldValues
  readOnly?: boolean
  children: React.ReactNode
}

export const StepForm = <TStepFieldValues extends FieldValues>({
  validationSchema = yupObject(),
  defaultValues,
  values,
  readOnly,
  children
}: StepComponentProps<TStepFieldValues>) => {
  const {updateCurrentStepValues, stepActions, updateCurrentStepValuesAndSubmit} =
    useSteppedFormContext()
  const methods = useForm<TStepFieldValues>({
    resolver: yupResolver(validationSchema),
    values,
    ...(!readOnly && {defaultValues})
  })

  const handleValidSubmit: SubmitHandler<TStepFieldValues> = (data) => {
    if (stepActions.canGoToNextStep) {
      updateCurrentStepValues(data)
      stepActions.goToNextStep()
    } else {
      updateCurrentStepValuesAndSubmit(data)
    }
  }

  return (
    <FormProvider {...methods}>
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <form onSubmit={methods.handleSubmit(handleValidSubmit)} style={{width: '100%'}}>
        <Stack spacing={2} alignItems="center" useFlexGap>
          {children}
        </Stack>
      </form>
    </FormProvider>
  )
}
