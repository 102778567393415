import {TFunction} from 'i18next'
import moment from 'moment'

export const formatMonthYearWithPrefix = (
  date: string,
  translationPrefix: string,
  t: TFunction
) => {
  const month = moment.utc(date).get('month')
  const year = moment.utc(date).get('year')
  const monthText = t(`${translationPrefix}.datePicker.months`, {returnObjects: true})[month]

  return `${monthText} ${year}`
}
