import {AxiosError} from 'axios'
import {useQuery, UseQueryOptions} from 'react-query'
import {useNavigate} from 'react-router-dom'

import {ApiPath} from '../../shared/consts'
import {handleError} from '../../shared/helpers/errorHandling'
import {useAxios} from '../../shared/hooks/useApi'
import {MyTasksSummary, TimeRange} from '../types'

const QueryKey = 'tasks'

export const useMyTasksSummary = (
  dateRange: TimeRange,
  options?: UseQueryOptions<MyTasksSummary, AxiosError>
) => {
  const axiosInstance = useAxios()
  const navigate = useNavigate()

  return useQuery<MyTasksSummary, AxiosError>(
    [QueryKey, dateRange],
    async () => {
      const response = await axiosInstance.get<MyTasksSummary>(
        `${ApiPath.TASKS}/my-tasks/summary`,
        {
          params: {from: dateRange.startDate, to: dateRange.endDate}
        }
      )

      return response.data
    },
    {
      onError: (err) => handleError(err, navigate),
      ...options
    }
  )
}
