import {LegendItem} from '@hconnect/common/components/runningTimes/LegendItem'
import {customThemeConstants} from '@hconnect/uikit/src/lib2'
import {InfoOutlined} from '@mui/icons-material'
import {Box, Stack, Tooltip, Typography, type TypographyProps} from '@mui/material'
import {type ChartOptions} from 'chart.js'
import {useTranslation} from 'react-i18next'

import {DoughnutChart} from '../../performanceDashboard/components/charts/DoughnutChart'
import {useTranslationPrefix} from '../../shared/hooks/useTranslationPrefix'
import {mapToWorkOrderSummaryChartDatasets} from '../mappers/work-orders.mappers'
import type {WorkOrderSummaryChartDataset, AggregatedWorkOrderSummaryDto} from '../types'

import {WorkOrdersOverdueTooltipTitle} from './WorkOrdersOverdueTooltipTitle'

const chartOptions: ChartOptions<'doughnut'> = {
  cutout: '78%',
  events: [],
  responsive: true
}

type Props = {
  data?: AggregatedWorkOrderSummaryDto
}

const EMPTY_DATASETS_COLOR = customThemeConstants().palette.neutralFaded20
const EMPTY_CHART_DATASETS = [{count: 1, color: EMPTY_DATASETS_COLOR}]
const createEmptyDatasetLegend = (datasets: WorkOrderSummaryChartDataset[]) => {
  return datasets.map((summary) => ({
    ...summary,
    color: EMPTY_DATASETS_COLOR
  }))
}

export const WorkOrdersSummaryChart = ({data}: Props) => {
  if (!data) return null

  const datasets = mapToWorkOrderSummaryChartDatasets(data)
  const sortedDatasets = [...datasets].sort((a, b) => b.count - a.count)
  const isEmptyDataset = datasets.every((summary) => summary.count === 0)
  const chartDatasets = isEmptyDataset ? EMPTY_CHART_DATASETS : sortedDatasets
  const emptyDatasetLegend = createEmptyDatasetLegend(datasets)

  return (
    <Box sx={{px: 3, display: 'flex', justifyContent: 'center'}}>
      <Box display="flex" alignItems="center" gap={{xs: 2, md: 4, lg: 8}}>
        <Box sx={{width: 230, height: 230, position: 'relative'}}>
          <DoughnutChart
            data={chartDatasets.map((summary) => summary.count)}
            backgroundList={chartDatasets.map((summary) => summary.color)}
            options={chartOptions}
          />
          <ChartCenteredContent
            total={data.totalWorkOrders}
            overdue={data.overdueWorkOrders}
            totalOverdue={data.totalOverdueWorkOrders}
            closed={data.closedWorkOrders}
          />
        </Box>
        <Box sx={{minWidth: 230}}>
          <WorkOrdersSummaryChartLegend data={isEmptyDataset ? emptyDatasetLegend : datasets} />
        </Box>
      </Box>
    </Box>
  )
}

type WorkOrdersSummaryChartLegendProps = {
  data: WorkOrderSummaryChartDataset[]
}

const WorkOrdersSummaryChartLegend = ({data}: WorkOrdersSummaryChartLegendProps) => {
  const {t} = useTranslation()
  const {hrocPrefix} = useTranslationPrefix()

  return (
    <Stack spacing={1} data-test-id="work-order-summary-chart-legend">
      {data.map(({typeName, color, count}) => {
        const nameLabel = t(`${hrocPrefix}.workOrderSummary.sapWorkOrderTypes.${typeName}`)

        return (
          <LegendItem
            key={typeName}
            color={color}
            label={`${nameLabel} (${count})`}
            data-test-id={`legend-item-for-${typeName}`}
          />
        )
      })}
    </Stack>
  )
}

type ChartCenteredContentProps = {
  total: number
  overdue: number
  totalOverdue: number
  closed: number
}

const ChartCenteredContent = ({
  total,
  overdue,
  totalOverdue,
  closed
}: ChartCenteredContentProps) => {
  const {t} = useTranslation()
  const {hrocPrefix} = useTranslationPrefix()
  const isOverdueWarningShown = totalOverdue > 0 && overdue !== totalOverdue
  const overdueValueColor = totalOverdue > 0 ? 'error' : 'textPrimary'

  return (
    <Box sx={{position: 'absolute', top: '25%', left: '40%'}}>
      <Stack spacing={0.5} alignItems="center">
        <ChartLabel
          label={t(`${hrocPrefix}.workOrderSummary.label.total`)}
          value={total}
          data-test-id="total-work-orders"
        />
        {isOverdueWarningShown ? (
          <Tooltip
            title={<WorkOrdersOverdueTooltipTitle overdue={overdue} totalOverdue={totalOverdue} />}
            arrow
            placement="right"
          >
            <span>
              <ChartLabel
                label={t(`${hrocPrefix}.workOrderSummary.label.overdue`)}
                value={totalOverdue}
                valueColor={overdueValueColor}
                showInfoIcon
                data-test-id="overdue-work-orders"
              />
            </span>
          </Tooltip>
        ) : (
          <ChartLabel
            label={t(`${hrocPrefix}.workOrderSummary.label.overdue`)}
            value={totalOverdue}
            valueColor={overdueValueColor}
            data-test-id="overdue-work-orders"
          />
        )}
        <ChartLabel
          label={t(`${hrocPrefix}.workOrderSummary.label.closed`)}
          value={closed}
          data-test-id="closed-work-orders"
        />
      </Stack>
    </Box>
  )
}

type ChartLabelProps = {
  label: string
  value: number
  showInfoIcon?: boolean
  valueColor?: TypographyProps['color']
  ['data-test-id']?: string
}

const ChartLabel: React.FC<ChartLabelProps> = ({
  label,
  value,
  showInfoIcon,
  valueColor = 'textPrimary',
  'data-test-id': dataTestId
}) => {
  return (
    <Box data-test-id={dataTestId}>
      <Typography variant="caption" textAlign="center" lineHeight={1}>
        {label}
      </Typography>
      <Typography fontWeight="medium" textAlign="center" lineHeight={1} color={valueColor}>
        {value}
        {showInfoIcon && <InfoOutlined sx={{fontSize: 'inherit', verticalAlign: 'top', ml: 0.5}} />}
      </Typography>
    </Box>
  )
}
