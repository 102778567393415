import {Stack, Box} from '@mui/material'

import {useSteppedFormContext} from '../hooks/useStepperFormContext'

import {HorizontalStepper} from './HorizontalStepper'
import {StepForm} from './StepForm'

export const SteppedForm = () => {
  const {activeStep, stepsConfig, readOnly, stepsValues} = useSteppedFormContext()
  const activeStepIndex = activeStep - 1
  const stepConfig = stepsConfig[activeStepIndex]
  const {StepFormContent, validationSchema, defaultValues, key} = stepConfig
  const stepValues = stepsValues[key] ?? defaultValues

  return (
    <Box width="100%" display="flex" justifyContent="center">
      <Stack spacing={3} alignItems="center" useFlexGap width="100%">
        <Box width={{xs: '100%', md: '632px'}}>
          <HorizontalStepper activeStep={activeStep} steps={stepsConfig} />
        </Box>
        <StepForm
          key={activeStep}
          validationSchema={validationSchema}
          defaultValues={defaultValues}
          values={stepValues}
          readOnly={readOnly}
        >
          <StepFormContent readOnly={readOnly} />
        </StepForm>
      </Stack>
    </Box>
  )
}
