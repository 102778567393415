import {Route} from 'react-router-dom'

import {Context} from '../shared/types/temp.types'

import {APP_SELECT_SEGMENT} from './consts'
import {AppSelect} from './pages/AppSelect'
import {UpSell} from './pages/UpSell'

export const getEntrypointDashboardRoutes = (
  context: Context,
  enablePerformanceDashboard: boolean
) => {
  return [
    <Route
      path={'/goDigital'}
      key="entrypoint-dashboard-up-sell"
      element={<UpSell context={context} />}
    />,
    <Route
      path={enablePerformanceDashboard ? APP_SELECT_SEGMENT : '/'}
      key="entrypoint-dashboard-app-select"
      element={<AppSelect context={context} />}
    />
  ]
}
