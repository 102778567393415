import {allApps} from '@hconnect/common/hproduce/'
import {AppConfig, WhiteList} from '@hconnect/common/hproduce/types'
import {useMemo} from 'react'

export const useAppsByWhiteList: (
  whiteList: WhiteList
) => Record<'allowed' | 'unAllowed', AppConfig[]> = (whiteList) => {
  const {optionsPerApp} = whiteList
  return useMemo(
    () => ({
      allowed: allApps.filter((app) => optionsPerApp[app.name]),
      unAllowed: allApps.filter((app) => !optionsPerApp[app.name])
    }),
    [optionsPerApp]
  )
}
