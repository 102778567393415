import {Controller, type FieldValues} from 'react-hook-form'

import type {ControlledFormField} from '../../types'
import {UserSearchAutocomplete, type UserSearchAutocompleteProps} from '../inputs'

type UserSearchAutocompleteFieldProps<TField extends FieldValues> = ControlledFormField<TField> &
  UserSearchAutocompleteProps

export const UserSearchAutocompleteField = <TField extends FieldValues>({
  control,
  name,
  label,
  disabled,
  readOnly,
  disableClearable = true,
  helperText,
  ...otherAutocompleteProps
}: UserSearchAutocompleteFieldProps<TField>) => (
  <Controller
    name={name}
    control={control}
    disabled={disabled || readOnly}
    render={({field, fieldState}) => (
      <UserSearchAutocomplete
        {...otherAutocompleteProps}
        {...field}
        label={label}
        error={fieldState.invalid}
        helperText={fieldState.error?.message || helperText}
        readOnly={readOnly}
        disabled={disabled}
        onChange={(_, value) => field.onChange(value)}
        disableClearable={disableClearable}
      />
    )}
  />
)
