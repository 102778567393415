import {KpiScore} from '@hconnect/uikit/src/lib2'
import {InfoOutlined} from '@mui/icons-material'
import {Box, Stack, Tooltip, Typography} from '@mui/material'
import {useTranslation} from 'react-i18next'

import {useTranslationPrefix} from '../../shared/hooks/useTranslationPrefix'

type Props = {
  pending: number
  overdue: number
  done: number
}

export const MyTasksSummary = ({pending, overdue, done}: Props) => {
  const {t} = useTranslation()
  const {hrocPrefix} = useTranslationPrefix()

  const showOverdueWarning = overdue > 0
  const overdueVariant = showOverdueWarning ? 'negative' : 'neutral'

  return (
    <Stack direction="row" spacing={3} useFlexGap>
      <StackItem>
        <KpiScore
          label={t(`${hrocPrefix}.myTasksSummary.label.pending`)}
          value={pending}
          data-test-id="my-tasks-pending"
        />
      </StackItem>
      <StackItem>
        <Tooltip
          title={showOverdueWarning ? <OverdueLabelTooltip overdue={overdue} /> : ''}
          arrow
          placement="right"
        >
          <span>
            <KpiScore
              label={t(`${hrocPrefix}.myTasksSummary.label.overdue`)}
              labelIcon={showOverdueWarning ? <InfoOutlined /> : undefined}
              value={overdue}
              variant={overdueVariant}
              data-test-id="my-tasks-overdue"
            />
          </span>
        </Tooltip>
      </StackItem>
      <StackItem>
        <KpiScore
          label={t(`${hrocPrefix}.myTasksSummary.label.done`)}
          value={done}
          data-test-id="my-tasks-done"
        />
      </StackItem>
    </Stack>
  )
}

const StackItem: React.FC<{children: React.ReactNode}> = ({children}) => (
  <Box
    sx={{
      display: 'flex',
      flex: '1 0 0'
    }}
  >
    {children}
  </Box>
)

const OverdueLabelTooltip = ({overdue}: {overdue: number}) => {
  const {t} = useTranslation()
  const {hrocPrefix} = useTranslationPrefix()

  return (
    <Stack spacing={0.5}>
      <Typography fontWeight="medium">
        {`${overdue} ${t(`${hrocPrefix}.myTasksSummary.label.overdue`)} (${t(
          `${hrocPrefix}.label.allTime`
        )})`}
      </Typography>
    </Stack>
  )
}
