import {FlexPage} from '@hconnect/common/components/FlexPage'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router'

import {useTranslationPrefix} from '../../shared/hooks/useTranslationPrefix'

type PathParameter = {
  plantId?: string
}

export const ProcessEngineerDashboard = () => {
  const {plantId} = useParams<PathParameter>()
  if (!plantId) throw new Error('plantId is missing')

  const {t} = useTranslation()
  const {hrocPrefix} = useTranslationPrefix()

  return (
    <FlexPage title={t(`${hrocPrefix}.processEngineerDashboard.pageTitle`)}>
      Process Engineer Dashboard placeholder
    </FlexPage>
  )
}
