export type WorkOrderTypeName =
  | 'PM01'
  | 'PM02'
  | 'PM03'
  | 'PM04'
  | 'PM05'
  | 'PM06'
  | 'PM07'
  | 'PM08'
  | 'PM09'

export type WorkOrderTypeSummary = {
  typeName: WorkOrderTypeName
  count: number
}

export type AggregatedWorkOrderSummaryDto = {
  workOrderTypeSummaries: WorkOrderTypeSummary[]
  totalWorkOrders: number
  overdueWorkOrders: number
  totalOverdueWorkOrders: number
  closedWorkOrders: number
}

export type WorkOrderSummaryChartDataset = {
  typeName: WorkOrderTypeName
  count: number
  color: string
}
