import React from 'react'

import {type Context} from '../../shared/types/temp.types'

type UseUserContextReturn = Context['user']

type UserContextProviderProps = {
  initialState: Context['user']
  children?: React.ReactNode
}

const context = React.createContext<UseUserContextReturn | undefined>(undefined)

export const UserContextProvider: React.FC<UserContextProviderProps> = ({
  initialState,
  children
}) => {
  return <context.Provider value={initialState}>{children}</context.Provider>
}

export function useUserContext(): UseUserContextReturn {
  const ctx = React.useContext(context)
  if (ctx === undefined) {
    throw new Error('useUserContext was used outside the scope of a UserContextProvider!')
  } else {
    return ctx
  }
}
