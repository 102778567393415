import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  RadioGroupProps
} from '@mui/material'

import type {FormFieldBase} from '../../types'

export type Props<T extends string> = FormFieldBase & {
  value: T
  options: {value: T; label: string}[]
  onChange?: RadioGroupProps['onChange']
  row?: boolean
}

export const RadioButton = <T extends string>({
  value,
  label,
  name,
  disabled,
  readOnly,
  onChange,
  options,
  helperText,
  error,
  row = true
}: Props<T>) => {
  const labelId = `radio-buttons-group-${name}`

  return (
    <FormControl disabled={disabled} error={error} fullWidth>
      <FormLabel id={labelId} component="legend">
        {label}
      </FormLabel>
      <RadioGroup
        name={name}
        aria-labelledby={labelId}
        value={value}
        onChange={onChange}
        row={row}
        sx={{gap: 1, my: 1}}
      >
        {options.map((option) => (
          <FormControlLabel
            key={option.label}
            value={option.value}
            control={<Radio data-test-id={`radio-group-${name}-option-${option.value}`} />}
            label={option.label}
            disabled={readOnly && option.value !== value}
            sx={{
              border: ({palette}) => `1px solid ${palette.grey[200]}`,
              borderColor: value === option.value ? 'primary.main' : undefined,
              p: 0.5,
              pr: 1.5,
              borderRadius: 1,
              flex: '1 0 auto',
              marginLeft: 0,
              marginRight: 0
            }}
          />
        ))}
      </RadioGroup>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  )
}
