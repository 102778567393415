import {Stack, useTheme, useMediaQuery} from '@mui/material'
import {FieldValues, Path, useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {useTranslationPrefix} from '../../../shared/hooks/useTranslationPrefix'
import {YesNoFormValue, type ControlledFormField} from '../../types'

import {RadioButtonField} from './RadioButtonField'
import {TextField} from './TextField'

type YesNoWithCommentFieldProps<TField extends FieldValues> = ControlledFormField<TField>

export const YesNoWithCommentField = <TField extends FieldValues>({
  label,
  name,
  control,
  disabled,
  readOnly
}: YesNoWithCommentFieldProps<TField>) => {
  const {watch} = useFormContext()
  const {t} = useTranslation()
  const {hrocPrefix} = useTranslationPrefix()
  const value = watch(`${name}.value` as Path<TField>)
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

  const yesNoItemOptions = [
    {value: YesNoFormValue.YES, label: t(`${hrocPrefix}.label.yes`)},
    {value: YesNoFormValue.NO, label: t(`${hrocPrefix}.label.no`)}
  ]

  return (
    <Stack spacing={1}>
      <RadioButtonField
        label={label}
        options={yesNoItemOptions}
        disabled={disabled}
        readOnly={readOnly}
        name={`${name}.value` as Path<TField>}
        control={control}
        row={!isSmall}
      />
      {value === YesNoFormValue.NO && (
        <TextField
          name={`${name}.comment` as Path<TField>}
          label={t(`${hrocPrefix}.label.comment`)}
          control={control}
          disabled={disabled}
          readOnly={readOnly}
        />
      )}
    </Stack>
  )
}
