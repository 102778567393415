import {type AxiosError} from 'axios'
import {useSnackbar} from 'notistack'
import {useTranslation} from 'react-i18next'
import {useMutation, useQueryClient, type UseMutationOptions} from 'react-query'

import {ApiPath} from '../../../shared/consts'
import {useAxios} from '../../../shared/hooks/useApi'
import {useTranslationPrefix} from '../../../shared/hooks/useTranslationPrefix'
import type {ChecklistDto, UpdateChecklistResponseData} from '../../types'

import {ChecklistsQueryKey} from './useChecklist'

type ChecklistUpdateParams = {
  id: string
  plantId: string
}

export const useChecklistUpdate = (
  {id, plantId}: ChecklistUpdateParams,
  options?: UseMutationOptions<UpdateChecklistResponseData, AxiosError, ChecklistDto['data']>
) => {
  const {t} = useTranslation()
  const {hrocPrefix} = useTranslationPrefix()
  const axiosInstance = useAxios()
  const queryClient = useQueryClient()
  const {enqueueSnackbar} = useSnackbar()

  return useMutation<UpdateChecklistResponseData, AxiosError, ChecklistDto['data']>(
    async (checklistData) => {
      const response = await axiosInstance.patch<UpdateChecklistResponseData>(
        `${ApiPath.HROC_CHECKLISTS}/${plantId}/checklists/${id}`,
        checklistData
      )

      return response.data
    },
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries([ChecklistsQueryKey, plantId, id])
        await options?.onSuccess?.(data, variables, context)
        enqueueSnackbar(t(`${hrocPrefix}.checklist.update.success`), {
          variant: 'success'
        })
      },
      onError: async (error, ...args) => {
        const errorMessage =
          error.response?.status === 409
            ? t(`${hrocPrefix}.checklist.update.errorConflict`)
            : t(`${hrocPrefix}.checklist.update.error`)
        await options?.onError?.(error, ...args)
        enqueueSnackbar(errorMessage, {
          variant: 'error'
        })
      }
    }
  )
}
