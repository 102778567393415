import type {SensorReadings, SignalData} from '@hconnect/common/components/kmsStatus'

export const mapToSensorReadings = (data: SignalData[]): SensorReadings =>
  data.reduce((acc, sensor) => {
    if (sensor.localName) {
      acc[sensor.localName] = {
        lastReading: sensor.lastReading,
        lastModelBreach: sensor.lastModelBreach
      }
    }
    return acc
  }, {})
