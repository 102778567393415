import {Controller, type FieldValues} from 'react-hook-form'

import type {ControlledFormField} from '../../types'
import {Autocomplete, type AutocompleteProps} from '../inputs'

type AutocompleteFieldProps<
  TOption extends {id: string},
  TField extends FieldValues
> = ControlledFormField<TField> & AutocompleteProps<TOption>

export const AutocompleteField = <TOption extends {id: string}, TField extends FieldValues>({
  control,
  name,
  options,
  label,
  disabled,
  readOnly,
  disableClearable = true,
  helperText,
  ...otherAutocompleteProps
}: AutocompleteFieldProps<TOption, TField>) => (
  <Controller
    name={name}
    control={control}
    disabled={disabled || readOnly}
    render={({field, fieldState}) => (
      <Autocomplete
        {...otherAutocompleteProps}
        {...field}
        label={label}
        options={options}
        error={fieldState.invalid}
        helperText={fieldState.error?.message || helperText}
        readOnly={readOnly}
        disabled={disabled}
        onChange={(_, value) => field.onChange(value)}
        disableClearable={disableClearable}
      />
    )}
  />
)
