import {PlantSelect} from '@hconnect/common/components/plantSelect'
import {AppName, WhiteList} from '@hconnect/common/hproduce/types'
import React from 'react'

import {Shell} from '../../shared/components/Shell'
import {Context} from '../../shared/types/temp.types'

type Props = {
  appName: AppName
  whiteList: WhiteList
  context: Context
}

export const PlantSelectPage: React.FC<Props> = (props) => {
  const {whiteList, appName} = props

  const plants = whiteList.optionsPerApp[appName] || []
  return (
    <Shell context={props.context} appName={appName}>
      <PlantSelect plants={plants} translationPrefix="entryPoint" />
    </Shell>
  )
}
