import {KpiThresholdIcon} from '@hconnect/common/components/kpiPerformance/KpiThresholdIcon'
import {KpiScore} from '@hconnect/uikit/src/lib2'
import {Grid, Theme, useMediaQuery} from '@mui/material'
import {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {useTranslationPrefix} from '../../shared/hooks/useTranslationPrefix'
import type {ReliabilityDailyKpis} from '../types'
import {formatKpiValue} from '../utils'

type Props = {
  dailyKpis?: ReliabilityDailyKpis
}

const SINGLE_COLUMN_BREAKPOINT = 380

export const PlantStatusDailyKpis: React.FC<Props> = ({dailyKpis}) => {
  const {t, i18n} = useTranslation()
  const language = i18n.language
  const {hrocPrefix} = useTranslationPrefix()
  const isSingleColumn = useMediaQuery(({breakpoints}: Theme) =>
    breakpoints.down(SINGLE_COLUMN_BREAKPOINT)
  )

  const kpiItems = useMemo(
    () => [
      {
        key: 'clinker-production',
        label: t(`${hrocPrefix}.plantStatus.label.clinkerProduction`),
        value: formatKpiValue(dailyKpis?.clinkerProduction?.value, language),
        unit: dailyKpis?.clinkerProduction?.unit,
        kpiBreachSeverity: dailyKpis?.clinkerProduction?.kpiBreachSeverity
      },
      {
        key: 'kiln-operating-coefficient',
        label: t(`${hrocPrefix}.plantStatus.label.kilnOperatingCoefficient`),
        value: t(`${hrocPrefix}.plantStatus.label.notApplicable`)
      },
      {
        key: 'cement-production',
        label: t(`${hrocPrefix}.plantStatus.label.cementProduction`),
        value: formatKpiValue(dailyKpis?.cementProduction?.value, language),
        unit: dailyKpis?.cementProduction?.unit,
        kpiBreachSeverity: dailyKpis?.cementProduction?.kpiBreachSeverity
      },
      {
        key: 'alternative-fuel-rate',
        label: t(`${hrocPrefix}.plantStatus.label.alternativeFuelRate`),
        value: formatKpiValue(dailyKpis?.alternativeFuelRate?.value, language),
        unit: dailyKpis?.alternativeFuelRate?.unit,
        kpiBreachSeverity: dailyKpis?.alternativeFuelRate?.kpiBreachSeverity
      },
      {
        key: 'mean-time-between-failure',
        label: t(`${hrocPrefix}.plantStatus.label.meanTimeBetweenFailure`),
        value: t(`${hrocPrefix}.plantStatus.label.notApplicable`)
      },
      {
        key: 'kiln-reliability-coefficient',
        label: t(`${hrocPrefix}.plantStatus.label.kilnReliabilityCoefficient`),
        value: t(`${hrocPrefix}.plantStatus.label.notApplicable`)
      }
    ],
    [dailyKpis, t, hrocPrefix, language]
  )

  return (
    <Grid container spacing={1.5} sx={{maxWidth: '100%'}}>
      {kpiItems.map((kpiItem) => (
        <Grid item xs={isSingleColumn ? 12 : 6} key={kpiItem.key}>
          <KpiScore
            value={kpiItem.value}
            label={kpiItem.label}
            unit={kpiItem.unit}
            valueIcon={
              kpiItem.kpiBreachSeverity ? (
                <KpiThresholdIcon status={kpiItem.kpiBreachSeverity} variant="filled" />
              ) : undefined
            }
            size="L"
            data-test-id={`kpi-score-${kpiItem.key}`}
          />
        </Grid>
      ))}
    </Grid>
  )
}
