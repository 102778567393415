import {customThemeConstants, TextOnBackground} from '@hconnect/uikit/src/lib2'
import {
  Stepper,
  Step,
  StepLabel as MuiStepLabel,
  stepLabelClasses,
  StepIcon as MuiStepIcon,
  StepConnector as MuiStepConnector,
  stepConnectorClasses,
  styled,
  Stack,
  useMediaQuery,
  useTheme
} from '@mui/material'
import {useTranslation} from 'react-i18next'

import {useTranslationPrefix} from '../../shared/hooks/useTranslationPrefix'

type Props = {
  activeStep: number
  steps: {key: string; label: string}[]
}

const StepConnector = styled(MuiStepConnector)({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 20,
    borderColor: customThemeConstants().palette.mediumInputBorder,
    left: 'calc(-50% + 28px)',
    right: 'calc(50% + 28px)'
  }
})

const StepLabel = styled(MuiStepLabel)(({theme}) => ({
  [`& .${stepLabelClasses.labelContainer} .${stepLabelClasses.alternativeLabel}`]: {
    color: theme.palette.common.white
  }
}))

const StepIcon = styled(MuiStepIcon)({
  width: 40,
  height: 40,
  border: '1px solid',
  borderRadius: '50%',
  borderColor: customThemeConstants().palette.mediumInputBorder,
  color: customThemeConstants().palette.primaryBackgroundColor
})

const CompactHorizontalStepper = ({
  activeLabel,
  activeStep,
  totalSteps
}: {
  activeLabel: string
  activeStep: number
  totalSteps: number
}) => {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const {t} = useTranslation()
  const {hrocPrefix} = useTranslationPrefix()

  return (
    <Stack direction="row" spacing={2} justifyContent="space-between" px={2}>
      <TextOnBackground variant={isSmall ? 'h5' : 'h3'} contrast="high">
        {activeLabel}
      </TextOnBackground>
      <TextOnBackground variant={isSmall ? 'body2' : 'body1'} contrast="low">
        {t(`${hrocPrefix}.label.stepsProgress`, {activeStep, totalSteps})}
      </TextOnBackground>
    </Stack>
  )
}

export const HorizontalStepper = ({steps, activeStep}: Props) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  if (isMobile) {
    return (
      <CompactHorizontalStepper
        activeLabel={steps[activeStep - 1]?.label}
        activeStep={activeStep}
        totalSteps={steps.length}
      />
    )
  }

  return (
    <Stepper activeStep={activeStep} alternativeLabel connector={<StepConnector />}>
      {steps.map(({key, label}, index) => (
        <Step key={key} active={activeStep === index + 1} completed={false}>
          <StepLabel StepIconComponent={StepIcon}>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  )
}
