import {countAreaState} from '@hconnect/common/components/kmsStatus'
import {useState, useEffect} from 'react'

import {mapToSensorReadings} from '../mappers'

import {useGetHealthIndicators, useGetKilnsInfo, useGetSignals} from './api'

export const useKilnMonitoringState = (plantId: string) => {
  const kilnsInfo = useGetKilnsInfo(plantId)
  const kilns = kilnsInfo.data
  const [selectedKilnId, setSelectedKilnId] = useState<string | null>(null)
  const healthIndicators = useGetHealthIndicators(
    {plantId, kilnId: selectedKilnId!},
    {enabled: !!selectedKilnId}
  )
  const sensorAreas = useGetSignals(
    {plantId, kilnId: selectedKilnId!},
    {
      enabled: !!selectedKilnId,
      select: (data) => countAreaState(data, mapToSensorReadings(data))
    }
  )

  useEffect(() => {
    if (kilns && kilns.length > 0) {
      if (!selectedKilnId || !kilns.some((kiln) => kiln.id === selectedKilnId)) {
        setSelectedKilnId(kilns[0].id)
      }
    }
  }, [kilns, selectedKilnId])

  const selectedKiln = kilns?.find((kiln) => kiln.id === selectedKilnId)

  return {
    kilnsInfo,
    healthIndicators,
    sensorAreas,
    selectedKiln,
    handleSelectedKilnChange: setSelectedKilnId
  }
}
