import {type TFunction} from 'i18next'

import {HROC_PREFIX} from '../../shared/consts'
import {
  ChecklistTemplateKind,
  YesNoFormValue,
  type ChecklistDto,
  type ChecklistFormValues,
  type TakeFiveChecklistData,
  type TakeFiveChecklistDto,
  type TakeFiveChecklistFormValues,
  type YesNoItemWithComment,
  type YesNoItemWithCommentFormValues
} from '../types'

export const mapChecklistDtoToChecklistFormValues = (
  checklist: ChecklistDto,
  t: TFunction
): ChecklistFormValues => {
  switch (checklist.kind) {
    case ChecklistTemplateKind.TakeFive:
      return mapTakeFiveChecklistDtoToFormValues(checklist, t)
    default:
      throw new Error(`Unknown checklist template kind: ${checklist.kind}`)
  }
}

export const mapChecklistFormValuesToChecklistDtoData = (
  formValues: ChecklistFormValues,
  templateKind: ChecklistTemplateKind
): ChecklistDto['data'] => {
  switch (templateKind) {
    case ChecklistTemplateKind.TakeFive:
      return mapTakeFiveChecklistFormValuesToDataDto(formValues)
    default:
      throw new Error(`Unknown checklist template kind: ${formValues.kind}`)
  }
}

const mapYesNoItemToFormItem = (item: YesNoItemWithComment): YesNoItemWithCommentFormValues => ({
  value: item.value ? YesNoFormValue.YES : YesNoFormValue.NO,
  comment: item.comment
})

const mapYesNoFormValuesToItem = (item: YesNoItemWithCommentFormValues): YesNoItemWithComment => ({
  value: item.value === YesNoFormValue.YES,
  comment: item.comment
})

export const mapTakeFiveChecklistDtoToFormValues = (
  {data, kind, preparedBy}: TakeFiveChecklistDto,
  t: TFunction
): TakeFiveChecklistFormValues => {
  const safety = data?.safety
  const hazardAssessment = data?.hazardAssessment

  return {
    kind,
    safety: safety
      ? {
          ...safety,
          peopleInformed: mapYesNoItemToFormItem(safety.peopleInformed),
          rightTools: mapYesNoItemToFormItem(safety.rightTools),
          fitForDuty: mapYesNoItemToFormItem(safety.fitForDuty),
          riskRecognized: mapYesNoItemToFormItem(safety.riskRecognized)
        }
      : null,
    hazardAssessment: hazardAssessment
      ? {
          ...hazardAssessment,
          hazards: hazardAssessment.hazards.map((hazard) => ({
            id: hazard,
            label: t(`${HROC_PREFIX}.takeFive.hazard.${hazard}`)
          })),
          risks: hazardAssessment.risks.map((risk) => ({
            id: risk,
            label: t(`${HROC_PREFIX}.takeFive.risk.${risk}`)
          })),
          preparedBy: preparedBy ? preparedBy.name : null
        }
      : null
  }
}

export const mapTakeFiveChecklistFormValuesToDataDto = ({
  safety,
  hazardAssessment
}: TakeFiveChecklistFormValues): TakeFiveChecklistData => {
  return {
    safety: safety
      ? {
          peopleInformed: mapYesNoFormValuesToItem(safety.peopleInformed),
          rightTools: mapYesNoFormValuesToItem(safety.rightTools),
          fitForDuty: mapYesNoFormValuesToItem(safety.fitForDuty),
          riskRecognized: mapYesNoFormValuesToItem(safety.riskRecognized),
          reasonToWorkSafe: safety.reasonToWorkSafe,
          supervisor: safety.supervisor
        }
      : null,
    hazardAssessment: hazardAssessment
      ? {
          workToBeDone: hazardAssessment.workToBeDone,
          hazards: hazardAssessment.hazards.map((hazard) => hazard.id),
          hazardsComment: hazardAssessment.hazardsComment,
          risks: hazardAssessment.risks.map((risk) => risk.id),
          risksComment: hazardAssessment.risksComment,
          eliminateControlHazard: hazardAssessment.eliminateControlHazard
        }
      : null
  }
}
