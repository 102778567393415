import {KilnMetricStatus, type SignalStatus} from '@hconnect/common/components/kmsStatus'
import {Grid} from '@mui/material'

import {useTranslationPrefix} from '../../shared/hooks/useTranslationPrefix'

type Props = {
  isLoading: boolean
  sensorAreas?: Record<string, string>
  gridItemSettings: {
    xs: number
    md: number
    lg: number
  }
}

export const KilnSensorsStatuses = ({isLoading, sensorAreas, gridItemSettings}: Props) => {
  const {hrocPrefix} = useTranslationPrefix()

  if (!sensorAreas) {
    return null
  }

  return (
    <Grid
      direction="row"
      gap={{
        xs: 1,
        sm: 3
      }}
      container
      data-test-id="kiln-sensors-statuses"
    >
      {Object.keys(sensorAreas).map((areaKey) => (
        <Grid
          item
          key={areaKey}
          {...gridItemSettings}
          data-test-id={`kiln-sensor-status-${areaKey}`}
        >
          <KilnMetricStatus
            title={areaKey}
            status={sensorAreas[areaKey] as SignalStatus}
            loading={isLoading}
            translationPrefix={hrocPrefix}
          />
        </Grid>
      ))}
    </Grid>
  )
}
