import {CardBox} from '@hconnect/uikit/src/lib2'
import {CalendarTodayOutlined} from '@mui/icons-material'
import {Box, Chip, Typography} from '@mui/material'
import moment from 'moment'
import {useTranslation} from 'react-i18next'

import {usePlantTimezone} from '../../shared/hooks/useTimezone'
import {useTranslationPrefix} from '../../shared/hooks/useTranslationPrefix'
import {CardLinkWrapper, DataContentWrapper, PlantStatusDailyKpis} from '../components'
import {useDailyPerformanceKpis, useMonthlyAggregatedKpis} from '../hooks/api'
import {getHasDenodoDataForDate} from '../utils'

import {getPerformancePagePath} from './PlantStatusKpisContainer'

type Props = {
  plantId: string
}

const AGGREGATION_MONTHS_COUNT = 1

export const PlantStatusDailyKpisContainer: React.FC<Props> = ({plantId}) => {
  const {t} = useTranslation()
  const {hrocPrefix} = useTranslationPrefix()
  const timezone = usePlantTimezone()
  const to = moment.utc().tz(timezone).startOf('month')
  const from = moment(to).subtract(AGGREGATION_MONTHS_COUNT, 'months')

  const {data: monthlyAggregatedKpis} = useMonthlyAggregatedKpis({plantId, from, to})

  const startOfYesterday = moment.utc().tz(timezone).subtract(1, 'days').startOf('day')
  const endOfYesterday = moment(startOfYesterday).endOf('day')
  const {
    data: yesterdayKpis,
    isLoading: isYesterdayKpisLoading,
    isError: isYesterdayKpisError,
    isRefetching: isYesterdayKpisRefetching,
    refetch: refetchYesterdayKpis
  } = useDailyPerformanceKpis({plantId, from: startOfYesterday, to: endOfYesterday})

  const lastMonth = moment.tz(timezone).subtract(1, 'month')
  const hasLastMonthDenodoData = getHasDenodoDataForDate(monthlyAggregatedKpis, lastMonth)
  const linkPath = getPerformancePagePath(
    plantId,
    hasLastMonthDenodoData ? 'lastMonth' : 'yesterday'
  )

  return (
    <CardLinkWrapper
      href={linkPath}
      data-test-id="plant-status-kpis-card-link-to-performance-plant-status"
    >
      <CardBox data-test-id="plant-status-kpis-card">
        <Box display="flex" flexDirection="row">
          <Box flex={1}>
            <Typography variant="h3" mb={2}>
              {t(`${hrocPrefix}.plantStatus.cardTitle`)}
            </Typography>
          </Box>
          <Chip
            icon={<CalendarTodayOutlined />}
            label={t(`${hrocPrefix}.label.yesterday`)}
            size="small"
            sx={{mx: 1}}
          />
        </Box>
        <DataContentWrapper
          isLoading={isYesterdayKpisLoading}
          isError={isYesterdayKpisError}
          isRefetching={isYesterdayKpisRefetching}
          data={yesterdayKpis}
          isNoData={() => !yesterdayKpis}
          retryFunction={() => void refetchYesterdayKpis()}
          progressSx={{color: 'primary.main'}}
        >
          <PlantStatusDailyKpis dailyKpis={yesterdayKpis} />
        </DataContentWrapper>
      </CardBox>
    </CardLinkWrapper>
  )
}
