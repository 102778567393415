import {type TFunction} from 'i18next'
import {object, string, array} from 'yup'

import {ValidationErrorKeys} from '../consts'
import {Hazard, Risk, YesNoFormValue} from '../types'

const COMMENT_MAX_LENGTH = 256

const getYesNoWithCommentSchema = ({t}: {t: TFunction}) =>
  object().shape({
    value: string()
      .oneOf([YesNoFormValue.YES, YesNoFormValue.NO])
      .required(t(ValidationErrorKeys.REQUIRED)),
    comment: string().when('value', {
      is: YesNoFormValue.NO,
      then: string()
        .required(t(ValidationErrorKeys.REQUIRED))
        .max(COMMENT_MAX_LENGTH, t(ValidationErrorKeys.MAX_LENGTH, {max: COMMENT_MAX_LENGTH})),
      otherwise: string()
    })
  })

const getUserSchema = ({t}: {t: TFunction}) =>
  object().shape({
    id: string().required(t(ValidationErrorKeys.REQUIRED)),
    name: string().required(t(ValidationErrorKeys.REQUIRED))
  })

const REASON_TO_WORK_SAFE_MAX_LENGTH = 128

export const getSafetyValidationSchema = ({t}: {t: TFunction}) =>
  object().shape({
    peopleInformed: getYesNoWithCommentSchema({t}),
    rightTools: getYesNoWithCommentSchema({t}),
    fitForDuty: getYesNoWithCommentSchema({t}),
    riskRecognized: getYesNoWithCommentSchema({t}),
    reasonToWorkSafe: string().max(
      REASON_TO_WORK_SAFE_MAX_LENGTH,
      t(ValidationErrorKeys.MAX_LENGTH, {max: REASON_TO_WORK_SAFE_MAX_LENGTH})
    ),
    supervisor: getUserSchema({t}).nullable().required(t(ValidationErrorKeys.REQUIRED))
  })

const WORK_TO_BE_DONE_MAX_LENGTH = 512
const OTHER_HAZARDS_MAX_LENGTH = 512
const OTHER_RISKS_MAX_LENGTH = 512
const ELIMINATE_CONTROL_HAZARD_MAX_LENGTH = 512

export const getHazardAssessmentValidationSchema = ({t}: {t: TFunction}) =>
  object().shape({
    workToBeDone: string()
      .max(
        WORK_TO_BE_DONE_MAX_LENGTH,
        t(ValidationErrorKeys.MAX_LENGTH, {max: WORK_TO_BE_DONE_MAX_LENGTH})
      )
      .required(t(ValidationErrorKeys.REQUIRED)),
    hazards: array().min(1, t(ValidationErrorKeys.REQUIRED)),
    hazardsComment: string().when('hazards', {
      is: (value) => value?.some(({id}) => id === Hazard.Other),
      then: string()
        .required(t(ValidationErrorKeys.REQUIRED))
        .max(
          OTHER_HAZARDS_MAX_LENGTH,
          t(ValidationErrorKeys.MAX_LENGTH, {max: OTHER_HAZARDS_MAX_LENGTH})
        ),
      otherwise: string().max(
        OTHER_HAZARDS_MAX_LENGTH,
        t(ValidationErrorKeys.MAX_LENGTH, {max: OTHER_HAZARDS_MAX_LENGTH})
      )
    }),
    risks: array().min(1, t(ValidationErrorKeys.REQUIRED)),
    risksComment: string().when('risks', {
      is: (value) => value?.some(({id}) => id === Risk.Other),
      then: string()
        .required(t(ValidationErrorKeys.REQUIRED))
        .max(
          OTHER_RISKS_MAX_LENGTH,
          t(ValidationErrorKeys.MAX_LENGTH, {max: OTHER_RISKS_MAX_LENGTH})
        ),
      otherwise: string().max(
        OTHER_RISKS_MAX_LENGTH,
        t(ValidationErrorKeys.MAX_LENGTH, {max: OTHER_RISKS_MAX_LENGTH})
      )
    }),
    eliminateControlHazard: string()
      .max(
        ELIMINATE_CONTROL_HAZARD_MAX_LENGTH,
        t(ValidationErrorKeys.MAX_LENGTH, {max: ELIMINATE_CONTROL_HAZARD_MAX_LENGTH})
      )
      .required(t(ValidationErrorKeys.REQUIRED))
  })
