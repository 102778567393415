import React, {createContext, useCallback, useContext, useMemo, useState} from 'react'
import {type FieldValues} from 'react-hook-form'
import {type AnyObjectSchema} from 'yup'

import {useStep, type UseStepActions} from '../../shared/hooks/useStep'

export type StepsConfig<TStepsValues extends FieldValues = FieldValues> = {
  key: keyof TStepsValues
  label: string
  StepFormContent: React.ElementType
  validationSchema?: AnyObjectSchema
  defaultValues?: TStepsValues[keyof TStepsValues]
}[]

type UseSteppedFormReturn<TStepsValues extends FieldValues = FieldValues> = {
  activeStep: number
  stepsConfig: StepsConfig
  stepsValues: TStepsValues
  stepActions: UseStepActions
  updateCurrentStepValues: (values: Partial<TStepsValues[keyof TStepsValues]>) => void
  updateCurrentStepValuesAndSubmit: (values: Partial<TStepsValues[keyof TStepsValues]>) => void
  onCancel?: () => void
  isSubmitting?: boolean
  readOnly?: boolean
}

const SteppedFormContext = createContext<UseSteppedFormReturn | null>(null)

export const useSteppedFormContext = <T extends FieldValues>() => {
  const context = useContext(SteppedFormContext)
  if (!context) {
    throw new Error('useSteppedFormContext must be used within a SteppedFormProvider')
  }
  return context as UseSteppedFormReturn<T>
}

type SteppedFormProviderProps<TFieldValues> = {
  children: React.ReactNode
  initialValues: TFieldValues
  stepsConfig: StepsConfig
  readOnly?: boolean
  onCancel?: () => void
  onSubmit?: (values: TFieldValues) => void
  isSubmitting?: boolean
}

export const SteppedFormProvider = <T extends FieldValues>({
  children,
  initialValues = {} as T,
  stepsConfig,
  readOnly = false,
  onCancel,
  onSubmit,
  isSubmitting
}: SteppedFormProviderProps<T>) => {
  const [stepsValues, setStepsValues] = useState(initialValues)
  const [activeStep, stepActions] = useStep(stepsConfig.length)
  const activeStepKey = stepsConfig[activeStep - 1].key

  const updateStepValues = useCallback(
    (step: keyof T, data: Partial<T[keyof T]>) => {
      if (readOnly) return

      setStepsValues((prevData) => ({...prevData, [step]: data}))
    },
    [readOnly]
  )

  const updateCurrentStepValues = useCallback(
    (data: Partial<T[keyof T]>) => {
      updateStepValues(activeStepKey, data)
    },
    [activeStepKey, updateStepValues]
  )

  const updateCurrentStepValuesAndSubmit = useCallback(
    (data: Partial<T[keyof T]>) => {
      updateCurrentStepValues(data)
      const updatedStepsValues = {...stepsValues, [activeStepKey]: data}
      onSubmit?.(updatedStepsValues)
    },
    [activeStepKey, updateCurrentStepValues, stepsValues, onSubmit]
  )

  const handleCancel = useCallback(() => onCancel?.(), [onCancel])

  const value = useMemo<UseSteppedFormReturn<T>>(
    () => ({
      activeStep,
      stepsConfig,
      stepsValues,
      stepActions,
      readOnly,
      updateCurrentStepValues,
      onCancel: handleCancel,
      updateCurrentStepValuesAndSubmit,
      isSubmitting
    }),
    [
      activeStep,
      stepsConfig,
      stepsValues,
      stepActions,
      readOnly,
      updateCurrentStepValues,
      handleCancel,
      updateCurrentStepValuesAndSubmit,
      isSubmitting
    ]
  )

  return <SteppedFormContext.Provider value={value}>{children}</SteppedFormContext.Provider>
}
