import {
  KilnMetricStatus,
  type HealthIndicatorsApi,
  type KilnInfo
} from '@hconnect/common/components/kmsStatus'
import {Grid} from '@mui/material'
import {useTranslation} from 'react-i18next'

import {useTranslationPrefix} from '../../shared/hooks/useTranslationPrefix'

export const KilnMetricStatuses = ({
  isLoading,
  kilnInfo,
  healthIndicators,
  gridItemSettings
}: {
  isLoading: boolean
  kilnInfo?: KilnInfo | null
  healthIndicators?: HealthIndicatorsApi
  gridItemSettings: {
    xs: number
    md: number
    lg: number
  }
}) => {
  const {t} = useTranslation()
  const {hrocPrefix} = useTranslationPrefix()

  return (
    <Grid
      direction="row"
      gap={{
        xs: 1,
        sm: 3
      }}
      container
      data-test-id="kiln-metric-statuses"
    >
      <Grid item {...gridItemSettings} data-test-id="kiln-metric-speed">
        <KilnMetricStatus
          title={t(`${hrocPrefix}.kilnMetrics.speed`)}
          value={`${healthIndicators?.speed?.value?.toFixed(1) ?? '-'} ${
            healthIndicators?.speed?.unit ?? ''
          }`}
          status={healthIndicators?.speed?.status}
          loading={isLoading}
          signals={kilnInfo?.layout?.speedSignals}
          translationPrefix={hrocPrefix}
        />
      </Grid>
      <Grid item {...gridItemSettings} data-test-id="kiln-metric-feed">
        <KilnMetricStatus
          title={t(`${hrocPrefix}.kilnMetrics.feed`)}
          value={`${healthIndicators?.feed?.value?.toFixed(0) ?? '-'} ${
            healthIndicators?.feed?.unit ?? ''
          }`}
          status={healthIndicators?.feed?.status}
          loading={isLoading}
          signals={kilnInfo?.layout?.feedSignals}
          translationPrefix={hrocPrefix}
        />
      </Grid>
      <Grid item {...gridItemSettings} data-test-id="kiln-metric-ovality">
        <KilnMetricStatus
          title={t(`${hrocPrefix}.kilnMetrics.ovality`)}
          loading={isLoading}
          status={healthIndicators?.ovality?.status}
          signals={kilnInfo?.layout?.ovalitySignals}
          disabled={!kilnInfo?.layout?.ovalitySignals?.length}
          translationPrefix={hrocPrefix}
        />
      </Grid>
      <Grid item {...gridItemSettings} data-test-id="kiln-metric-axial-balance">
        <KilnMetricStatus
          title={t(`${hrocPrefix}.kilnMetrics.axialBalance`)}
          loading={isLoading}
          disabled
          translationPrefix={hrocPrefix}
        />
      </Grid>
      <Grid item {...gridItemSettings} data-test-id="kiln-metric-warp">
        <KilnMetricStatus
          title={t(`${hrocPrefix}.kilnMetrics.warp`)}
          loading={isLoading}
          status={healthIndicators?.crank?.status}
          disabled={
            !kilnInfo?.layout?.crankSignals || !Object.keys(kilnInfo.layout.crankSignals).length
          }
          signals={kilnInfo?.layout?.crankSignals}
          translationPrefix={hrocPrefix}
        />
      </Grid>
    </Grid>
  )
}
