import {Link, LinkProps} from '@mui/material'

import {CARD_CHEVRON_CLASS} from './CardLinkChevron'

export const CardLinkWrapper = (props: LinkProps) => (
  <Link
    {...props}
    sx={(theme) => ({
      width: '100%',
      textDecoration: 'none',
      '&:hover': {
        [`& .${CARD_CHEVRON_CLASS}`]: {
          color: theme.palette.primary.main
        }
      }
    })}
  />
)
