import {AppLogo} from '@hconnect/uikit/src/common/assets/HProduceApps/'
import {useMobileBreakPoint} from '@hconnect/uikit/src/lib/Hooks/useBreakPoints'
import {Typography, Box} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {NavLink} from 'react-router-dom'

import {Shell} from '../../shared/components/Shell'
import {Context} from '../../shared/types/temp.types'
import {useAppsByWhiteList} from '../hooks/useAppsByWhiteList'

type Props = {
  context: Context
}

export const AppSelect: React.FC<Props> = ({context}) => {
  const isMobile = useMobileBreakPoint()
  const {t} = useTranslation()

  const apps = useAppsByWhiteList(context.whiteList)
  const logoSize = isMobile ? 80 : 120
  const smLogoSize = logoSize * 0.27

  return (
    <Shell context={context}>
      <Box
        display="flex"
        justifyContent="center"
        flexWrap="wrap"
        flexGrow={1}
        alignContent="center"
      >
        {apps.allowed.length === 0 ? (
          <Box width="100%" textAlign="center">
            <Typography variant="h1">{t('entryPoint.plantSelection.noAppWhiteListed')}</Typography>
          </Box>
        ) : (
          apps.allowed.map(({name: appName}) => (
            <Box
              component={NavLink}
              key={appName}
              to={`/${appName}`}
              sx={(theme) => ({
                textAlign: 'center',
                padding: 5,
                color: theme.palette.text.secondary,
                textDecoration: 'none',
                fontSize: 20,
                display: 'flex',
                flexDirection: 'column'
              })}
              data-test-id={`app-select-${appName}`}
            >
              <Box
                component={AppLogo}
                name={appName}
                size={logoSize}
                sx={{
                  transition: 'filter 400ms ease-in-out',
                  filter: 'drop-shadow(0 16px 32px rgba(0, 0, 0, 0.16))',
                  '&:hover': {
                    filter: 'drop-shadow(0px 24px 32px rgba(0, 0, 0, 0.5));'
                  }
                }}
              />
              <Box
                component="span"
                mt={2}
                sx={(theme) => ({
                  color: theme.palette.common.white
                })}
              >
                {appName}
              </Box>
            </Box>
          ))
        )}

        {apps.unAllowed.length > 0 && (
          <Box
            component={NavLink}
            to="/goDigital"
            sx={(theme) => ({
              textAlign: 'center',
              padding: 5,
              color: theme.palette.text.secondary,
              textDecoration: 'none',
              fontSize: 20,
              display: 'flex',
              flexDirection: 'column'
            })}
          >
            <Box
              width={logoSize}
              height={logoSize}
              sx={(theme) => ({
                background: 'rgba(255, 255, 255, 0.05)',
                border: `1px solid ${theme.palette.grey['600']}`,
                borderRadius: 3,
                padding: '16%',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
                rowGap: 1.5,
                transition: 'filter 400ms ease-in-out',
                filter: 'drop-shadow(0 16px 32px rgba(0, 0, 0, 0.16))',
                '&:hover': {
                  filter: 'drop-shadow(0px 24px 32px rgba(0, 0, 0, 0.5));'
                }
              })}
            >
              {apps.unAllowed.slice(0, 4).map(({name: appName}) => (
                <AppLogo key={appName} name={appName} size={smLogoSize} />
              ))}
            </Box>
            <Box
              component="span"
              mt={2}
              sx={(theme) => ({
                color: theme.palette.common.white
              })}
            >
              {t('entryPoint.marketing.more')}
            </Box>
          </Box>
        )}
      </Box>
    </Shell>
  )
}
