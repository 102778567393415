import type {User} from '@hconnect/common/types'
import {type AxiosError} from 'axios'
import {useQuery, type UseQueryOptions} from 'react-query'

import {ApiPath} from '../../../shared/consts'
import {useAxios} from '../../../shared/hooks/useApi'

const QueryKey = 'user-search'
const CACHE_TIME_IN_MS = 1000 * 60

export const useUserSearch = (
  plantId: string,
  namePattern: string | undefined,
  options?: UseQueryOptions<User[], AxiosError>
) => {
  const axiosInstance = useAxios()

  return useQuery<User[], AxiosError>(
    [QueryKey, namePattern, plantId],
    async () => {
      const path = `${ApiPath.HROC_CHECKLISTS}/${plantId}/users/supervisors`

      if (!namePattern) {
        return Promise.resolve([])
      }
      const response = await axiosInstance.get<User[]>(path, {params: {namePattern}})
      return response.data
    },
    {
      cacheTime: CACHE_TIME_IN_MS,
      refetchOnWindowFocus: false,
      ...options
    }
  )
}
