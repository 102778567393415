import {Permission, PermissionType} from '@hconnect/apiclient'
import {getPlantIdsForCountry, parseDataScope} from '@hconnect/common/hproduce/whiteListCheck'

import {usePermissionsContext} from '../../shared/hooks/usePermission'

export const getPlantIdsForPermissions = (permissions: Permission[]) => {
  const plantIdsSet = new Set<string>()

  permissions.forEach(({dataScope}) => {
    const {plantId, countryId} = parseDataScope(dataScope)

    if (plantId) {
      plantIdsSet.add(plantId)
    } else if (countryId) {
      getPlantIdsForCountry(countryId).forEach((id) => plantIdsSet.add(id))
    }
    // we ignore other scopes as we cannot determine plantIds for them from plants.json config
  })

  return Array.from(plantIdsSet)
}

export const usePlantsByPermission = (permission: PermissionType) => {
  const permissions = usePermissionsContext()
  const filteredPermissions = permissions.filter((p) => p.permissionType === permission)
  const plantIds = getPlantIdsForPermissions(filteredPermissions)

  return {plantIds}
}
